import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Spinner from 'components/Spinner';

function GroupDetailsButton({ groupId, setRedirectUrl }) {
  return (
    <Button
      onClick={() => {
        setRedirectUrl(`/groups/${groupId}`);
      }}
    >{'>'}</Button>
  );
}

function GroupsTableRow({ group, setRedirectUrl }) {
  return (
    <tr>
      <td>{group.name}</td>
      <td>
        <GroupDetailsButton groupId={group.id} setRedirectUrl={setRedirectUrl} />
      </td>
    </tr>
  );
}

export function GroupsTable(props) {
  const { groups, setRedirectUrl } = props;

  const { t } = useTranslation();

  if (!groups) {
    return <Spinner />;
  }

  return (
    <Table responsive striped className="regenagri-groups-table">
      <thead>
        <tr>
          <th>{t("Group Name")}</th>
          <th>{t("Users")}</th>
        </tr>
      </thead>
      <tbody>
        {groups
          .sort((a, b) => (a.name.localeCompare(b.name)))
          .map((group, idx) => <GroupsTableRow group={group} key={idx} setRedirectUrl={setRedirectUrl} />)
        }
      </tbody>
    </Table>
  );
}
