import React from 'react';
import { Alert } from 'react-bootstrap';

import Translation from 'locales/Translation';
import { BackToLoginButton } from 'common/backToLogin';

const { Trans } = Translation.setup();

function RegisterForm() {
  return (
    <div className='regenagri-small-form-box'>
      <BackToLoginButton />
      <Alert variant="success">
        <Trans i18nKey="regenagriSignUpInfo" components={{ mail: <a href='mailto:info@regenagri.org'>info@regenagri.org</a> }} />
      </Alert>
    </div>
  );
}

export default RegisterForm;
