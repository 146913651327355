import React, { useContext, useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import qs from 'qs';

import Spinner from 'components/Spinner';
import BackendClientContext from 'contexts/BackendClient';
import Translation from 'locales/Translation';
import ScorecardHeader from 'components/Scorecard/ScorecardHeader';
import ScorecardComponent from 'components/Scorecard/ScorecardComponent';
import CarbonAssessments from 'components/Scorecard/CarbonAssessments';
import SynergyPoints from 'components/Scorecard/SynergyPoints';
import { ensureFalsyOrArray } from 'utils';

const { useTranslation, Trans } = Translation.setup();

export function CombineScorecard(props) {
  const { error, scorecardResult } = props;
  const { t } = useTranslation();

  if (!scorecardResult && !error) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')} - {t('scorecard')}</title>
      </Helmet>
      <div className="regenagri-scorecard regenagri-combine-scorecard">
        {error && <Alert variant="danger">{error}</Alert>}
        {scorecardResult && <ScorecardHeader scorecardResult={scorecardResult} />}
        {scorecardResult && scorecardResult.components && scorecardResult.components.map(
          (component) => <ScorecardComponent component={component} key={component.id} />
        )}
        {scorecardResult && <SynergyPoints synergyPoints={scorecardResult.synergyPoints} />}
        {scorecardResult && <CarbonAssessments scorecardResult={scorecardResult} />}
        <div className="regenagri-scorecard-footer">
          <Trans i18nKey="scorecardFooter" components={{ mail: <a href='mailto:info@regenagri.org'>info@regenagri.org</a> }} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default function CombineScorecardWrapper(props) {
  const { location } = props;
  const { t } = useTranslation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {
    assessmentId: assessmentIdList,
    farmName,
    totalAreaOfLandBeingAssessed,
    totalAreaOfLandBeingAssessedUnit,
    totalAgriculturalLand,
    totalAgriculturalLandUnit,
    totalConservationArea,
    totalConservationAreaUnit,
  } = params;
  const backendClient = useContext(BackendClientContext);

  const [scorecardResult, setScorecardResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const farmNameList = ensureFalsyOrArray(farmName);
    const getScorecard = async () => {
      try {
        const result = await backendClient.getCombinedScorecard(
          assessmentIdList,
          farmNameList,
          totalAreaOfLandBeingAssessed,
          totalAreaOfLandBeingAssessedUnit,
          totalAgriculturalLand,
          totalAgriculturalLandUnit,
          totalConservationArea,
          totalConservationAreaUnit,
        );
        setScorecardResult(result);
      } catch (err) {
        setError(`${t('An error occurred whilst trying to retrieve the scorecard:')} ${t(err.message)}`);
      }
    };
    if (!scorecardResult) {
      getScorecard();
    }
  }, [
    scorecardResult,
    assessmentIdList,
    farmName,
    backendClient,
    totalAreaOfLandBeingAssessed,
    totalAreaOfLandBeingAssessedUnit,
    totalAgriculturalLand,
    totalAgriculturalLandUnit,
    totalConservationArea,
    totalConservationAreaUnit,
    t,
  ]);

  return <CombineScorecard
    error={error}
    scorecardResult={scorecardResult}
  />;
}
