import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import PaginatedTable from 'components/PaginatedTable';
import BackendClientContext from 'contexts/BackendClient';
import { RedirectContext } from 'contexts/Routing';
import AuthContext from 'contexts/Auth';

import Translation from 'locales/Translation';

import './styles.scss';

const { useTranslation } = Translation.setup();

const REMOVE_USER_WARNING_MESSAGE = "Are you sure you want to remove this user?";

function UserVerifiedState({ userVerified, t }) {
  return (
    <span className={userVerified ? 'verified' : 'unverified'}>
      {userVerified ? t('yes') : t('no')}
    </span>
  );
}

function RemoveUserButton({ user, t, setUserToRemove }) {
  return (
    <Button onClick={() => setUserToRemove(user)}>
      {t('Remove')}
    </Button>
  );
}

function AssessmentButton({ userId, setRedirectUrl }) {
  return (
    <Button data-testid="assessmentsButton"
      onClick={() => {
        setRedirectUrl(`/group-admin/assessments?userId=${userId}`);
      }}
    >{'>'}</Button>
  );
}

function RemoveUserModal({
  userToRemove,
  removeUser,
  groupId,
  setUserToRemove,
  t,
}) {
  return (
    <Modal show={!!userToRemove}>
      <Modal.Header>
        <Modal.Title>{t('Warning')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{t(REMOVE_USER_WARNING_MESSAGE)}</Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="modal-proceed-button"
          onClick={() => removeUser(groupId, userToRemove.id, userToRemove.email)}
          variant="primary"
        >
          {t('Remove')}
        </Button>
        <Button
          data-testid="modal-cancel-button"
          onClick={() => setUserToRemove(null)}
          variant="secondary"
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function GroupUsersTableRow({
  user,
  setUserToRemove,
  setRedirectUrl,
  showAssessmentsButton,
  t,
  isAdmin,
}) {
  const shouldShowRemoveButton = isAdmin || !user.isGroupAdmin;
  return (
    <tr>
      <td>{user.email}</td>
      <td>
        <UserVerifiedState userVerified={user.isVerified} t={t} />
      </td>
      <td>{t(user.isGroupAdmin ? "admin" : "member")}</td>
      <td className="regenagri-assessments-action">
        {shouldShowRemoveButton && <RemoveUserButton user={user} setUserToRemove={setUserToRemove} t={t}/>}
      </td>
      {
        showAssessmentsButton && (
          <td> {user.isVerified && <AssessmentButton userId={user.id} setRedirectUrl={setRedirectUrl} />} </td>
        )
      }
    </tr>
  );
}

export function GroupUsersTable(props) {
  const {
    fetchUsers,
    groupId,
    setError,
    removeUser,
    userToRemove,
    setUserToRemove,
    redirectUrl,
    setRedirectUrl,
    showAssessmentsButton,
    t,
    isAdmin,
  } = props;

  const Redirect = useContext(RedirectContext);

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  let columns = [
    { title: t('Email') },
    { title: t('Verified') },
    { title: t('Role') },
  ];

  if (showAssessmentsButton) {
    columns = [
      ...columns,
      {
        title: t('Actions'),
        className: "regenagri-assessments-action",
      },
      { title: t('Assessments') },
    ];
  }

  return (
    <React.Fragment>
      <RemoveUserModal
        userToRemove={userToRemove}
        removeUser={removeUser}
        groupId={groupId}
        setUserToRemove={setUserToRemove}
        t={t}
      />
      <PaginatedTable
        columns={columns}
        fetchData={fetchUsers}
        fetchFinally={() => {}}
        setError={setError}
        limit={50}
      >
        {
          data => data.map((user, idx) => (
            <GroupUsersTableRow
              key={idx}
              user={user}
              setRedirectUrl={setRedirectUrl}
              setUserToRemove={setUserToRemove}
              showAssessmentsButton={showAssessmentsButton}
              t={t}
              isAdmin={isAdmin}
            />
          ))
        }
      </PaginatedTable>
    </React.Fragment>
  );
}

export default function GroupUsersTableWrapper(props) {
  const {
    setSuccess,
    setError,
    listGroupUsers,
    groupId,
    showAssessmentsButton,
  } = props;

  const [userToRemove, setUserToRemove] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const backendClient = useContext(BackendClientContext);
  const auth = useContext(AuthContext);

  const { t } = useTranslation();

  async function fetchUsers(limit, skip) {
    const { users, totalRecords } = await listGroupUsers(groupId, limit, skip);
    return {
      data: users,
      totalRecords,
    };
  }

  async function removeUser(groupId, userId, userEmail) {
    await backendClient.removeUser(groupId, userId);
    setUserToRemove(null);
    setSuccess(`${t('Successfully removed')} ${userEmail}`);
  }

  return <GroupUsersTable
    fetchUsers={fetchUsers}
    groupId={groupId}
    setError={setError}
    setUserToRemove={setUserToRemove}
    userToRemove={userToRemove}
    removeUser={removeUser}
    redirectUrl={redirectUrl}
    setRedirectUrl={setRedirectUrl}
    showAssessmentsButton={showAssessmentsButton}
    t={t}
    isAdmin={auth.isAdmin()}
  />;
}
