import React from 'react';
import { Alert } from 'react-bootstrap';
import Translation from 'locales/Translation';
import ScorecardTable from './ScorecardTable';
import DisaggregationTotalsTable from './DisaggregationTotalsTable';

const { useTranslation } = Translation.setup();

const entryHasFarmIdentifier = (entry) => !!entry.farm && !!entry.farm.farm_identifier;

function CarbonAssessmentDetailTable({ assessmentDetail, idx }) {
  const { t } = useTranslation();
  return (
    <div className="regenagri-carbon-detail" key={idx}>
      <h5> { t(assessmentDetail.name) } </h5>
      <ScorecardTable obj={assessmentDetail} />
    </div>
  );
}

function CarbonAssessmentForField({ fieldAssessment, titleKey }) {
  const { t } = useTranslation();
  const farmId = fieldAssessment.farm.farm_identifier;
  return (
    <div className="regenagri-carbon-assessment-section" key={farmId}>
      <h3>{t(titleKey, { farmId })}</h3>
      <h4>{t("Summary")}</h4>
      <h5>{t("Totals")}</h5>
      <ScorecardTable obj={fieldAssessment.summary} />
      <h5 style={{ textTransform: 'none' }}>{t("GHG Breakdown")}</h5>
      <DisaggregationTotalsTable obj={fieldAssessment.summary.disaggregation_totals} />
      <h4>{`${t("sources of emissions")} (kg)`}</h4>
      <div className="regenagri-carbon-assessment-details">
        { fieldAssessment.total_emissions
          .map((detail, idx) => <CarbonAssessmentDetailTable assessmentDetail={detail} idx={idx} key={idx} />)
        }
      </div>
    </div>
  );
}

/**
 * CFT does crop and dairy assessments as two separate queries. This component is for rendering one
 * or the other.
 */
function CarbonAssessment({ assessment, titleKey }) {
  if (assessment.error) {
    return <Alert variant="warning">{ assessment.error }</Alert>;
  }
  if (assessment instanceof Array) {
    return assessment
      .filter(entryHasFarmIdentifier)
      .map(entry => <CarbonAssessmentForField fieldAssessment={entry} titleKey={titleKey} key={titleKey} />);
  }
  return null;
}

export default function CarbonAssessments({ scorecardResult }) {
  const { t } = useTranslation();

  const hasCarbonCropAssessment = scorecardResult.carbonCropAssessment
    && scorecardResult.carbonCropAssessment.length > 1;
  const hasCarbonDairyAssessment = scorecardResult.carbonDairyAssessment
    && scorecardResult.carbonDairyAssessment.length > 1;

  return (
    <React.Fragment>
      {(hasCarbonCropAssessment || hasCarbonDairyAssessment) &&
        <>
          <hr />
          <Alert variant="warning" className='regenagri-ghg-warning'>{t("quality of GHG")}</Alert>
        </>
      }
      {scorecardResult.carbonCropAssessment && (
        <CarbonAssessment
          assessment={scorecardResult.carbonCropAssessment}
          titleKey={"carbonCropAssessmentFor"}
        />
      )}
      {scorecardResult.carbonDairyAssessment && (
        <CarbonAssessment
          assessment={scorecardResult.carbonDairyAssessment}
          titleKey={"carbonDairyAssessmentFor"}
        />
      )}
    </React.Fragment>
  );
}
