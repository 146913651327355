import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import RegenAgriForm from 'components/RegenAgriForm';
import Translation from 'locales/Translation';
import { BackToLoginButton } from 'common/backToLogin';

const { useTranslation } = Translation.setup();

function RequestPasswordReset() {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(true);

  const existingSubmission = {
    data: {
      locale: Translation.getCurrentLanguage(),
    },
  };

  const onSuccess = () => {
    setShowInfo(false);
  };

  const successMarkup = (
    <React.Fragment>
      <p>
        {t('receiveResetEmail')}.
      </p>
      <p>
        {t('checkJunkMail')}.
      </p>
    </React.Fragment>
  );

  return (
    <div className="regenagri-small-form-box">
      <BackToLoginButton />
      {showInfo &&
        <Alert variant="info">
          {t('Please enter your email address to reset your password.')}
        </Alert>
      }
      <RegenAgriForm
        formId={"requestpasswordreset"}
        successMarkup={successMarkup}
        onSuccess={onSuccess}
        requiresAuthentication={false}
        existingSubmission={existingSubmission}
      />
    </div>
  );
}

export default RequestPasswordReset;
