import React from 'react';
import Translation from 'locales/Translation';

const { useTranslation, Trans } = Translation.setup();

export const ASSESSMENTS_PRIMER = () => {
  const { t } = useTranslation();
  return (<React.Fragment>
    <p>
      <Trans i18nKey="informationInMind" components={{ bold: <strong /> }} />
    </p>
    <ul>
      <li>{t('Acreage')}</li>
      <li>{t('Information about farm water usage (including total litres used)')}</li>
      <li>{t('Fertilisers, herbicides and/or pesticides usage figures per field')}</li>
      <li>{t('(if you keep them) Livestock units')}</li>
      <li>{t('Details of current conservation areas')}</li>
      <li>{t('Litres of diesel usage')}</li>
      <li>{t('The results of any soil sampling carried-out on your land')}</li>
    </ul>
  </React.Fragment>);
};
