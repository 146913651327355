import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Spinner from 'components/Spinner';
import BackendClientContext from 'contexts/BackendClient';
import Translation from 'locales/Translation';
import { RedirectContext } from 'contexts/Routing';
import CarbonAssessments from 'components/Scorecard/CarbonAssessments';
import ScorecardComponent from 'components/Scorecard/ScorecardComponent';
import ScorecardHeader from 'components/Scorecard/ScorecardHeader';
import SynergyPoints from 'components/Scorecard/SynergyPoints';
import './styles.scss';

const { useTranslation, Trans } = Translation.setup();

function UncloseableAssessmentButtons({
  setRedirectUrl,
  setOverrideWarnings,
  assessmentId,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        onClick={() => setRedirectUrl(`/assessments/${assessmentId}/profileinformation`)}
      >
        {t('Return to assessment')}
      </Button>
      <Button
        onClick={() => setOverrideWarnings(true)}
      >
        {t('Close assessment anyway')}
      </Button>
    </div>
  );
}

export function Scorecard(props) {
  const {
    assessmentId,
    error,
    assessmentCouldNotBeClosed,
    redirectUrl,
    setRedirectUrl,
    scorecardResult,
    setOverrideWarnings,
    t,
  } = props;

  const Redirect = useContext(RedirectContext);

  if (!scorecardResult && !error) {
    return <Spinner />;
  }

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')} - {t('scorecard')}</title>
      </Helmet>
      <div className="regenagri-scorecard">
        {error && <Alert variant="danger">{error}</Alert>}
        {assessmentCouldNotBeClosed && (
          <UncloseableAssessmentButtons
            setRedirectUrl={setRedirectUrl}
            setOverrideWarnings={setOverrideWarnings}
            assessmentId={assessmentId}
          />
        )}
        {scorecardResult && <ScorecardHeader scorecardResult={scorecardResult} />}
        {scorecardResult && scorecardResult.components.map(
          (component) => <ScorecardComponent component={component} key={component.id} />
        )}
        {scorecardResult && <SynergyPoints synergyPoints={scorecardResult.synergyPoints} />}
        {scorecardResult && <CarbonAssessments scorecardResult={scorecardResult} />}
        <div className="regenagri-scorecard-footer">
          <Trans i18nKey="scorecardFooter" components={{ mail: <a href='mailto:info@regenagri.org'>info@regenagri.org</a> }} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default function ScorecardWrapper(props) {
  const { match } = props;
  const { assessmentId } = match.params;

  const { t } = useTranslation();

  const [scorecardResult, setScorecardResult] = useState(null);
  const [error, setError] = useState(null);
  const [assessmentCouldNotBeClosed, setAssessmentCouldNotBeClosed] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [overrideWarnings, setOverrideWarnings] = useState(false);

  const backendClient = useContext(BackendClientContext);

  useEffect(() => {
    const getScorecard = async () => {
      try {
        setError(null);
        setAssessmentCouldNotBeClosed(false);
        const result = await backendClient.getScorecard(assessmentId, overrideWarnings);
        setOverrideWarnings(false);
        setScorecardResult(result);
      } catch (err) {
        if (err.errorCode === "CANNOT_CLOSE_ASSESSMENT") {
          setAssessmentCouldNotBeClosed(true);
        }
        setError(`${t('An error occurred whilst trying to retrieve the scorecard:')} ${t(err.message)}`);
      }
    };
    getScorecard();
  }, [assessmentId, backendClient, overrideWarnings, t]);

  return <Scorecard
    assessmentId={assessmentId}
    scorecardResult={scorecardResult}
    setScorecardResult={setScorecardResult}
    error={error}
    setError={setError}
    assessmentCouldNotBeClosed={assessmentCouldNotBeClosed}
    redirectUrl={redirectUrl}
    setRedirectUrl={setRedirectUrl}
    setOverrideWarnings={setOverrideWarnings}
    t={t}
  />;
}
