import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import AuthContext from "contexts/Auth";
import BackendClientContext from 'contexts/BackendClient';
import { RedirectContext } from 'contexts/Routing';
import Translation from 'locales/Translation';
import { GroupsTable } from 'components/GroupsTable';
import AddGroup from 'components/AddGroup';

import "./styles.scss";

const { useTranslation } = Translation.setup();

function TopButtons({ setRedirectUrl }) {
  const { t } = useTranslation();
  return (
    <div className="regenagri-admin-assessments-top-buttons">
      <Button
        onClick={() => setRedirectUrl(`/admin/dashboard`)}
      >
        {t('Back To Dashboard')}
      </Button>
    </div>
  );
}

export function Groups(props) {
  const {
    groups,
    error,
    setError,
    isAuthenticated,
    isAdmin,
    setRedirectUrl,
    redirectUrl,
  } = props;

  const Redirect = useContext(RedirectContext);
  const { t } = useTranslation();

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login?authRequired=true" />;
  }

  if (!isAdmin) {
    return <Redirect to="/assessments" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')} - {t('groups')}</title>
      </Helmet>
      <TopButtons setRedirectUrl={setRedirectUrl} />
      <h1>{t('Groups')}</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <AddGroup
        setError={setError}
        onNewGroupAdded={() => ({})}
      />
      <GroupsTable
        groups={groups}
        setRedirectUrl={setRedirectUrl}
      />
    </React.Fragment>
  );
}

export default function GroupsWrapper(props) {
  const auth = useContext(AuthContext);
  const backendClient = useContext(BackendClientContext);
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [groups, setGroups] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const getGroups = async () => {
      try {
        const { groups: receivedGroups } = await backendClient.getGroups();
        setGroups(receivedGroups);
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve groups:")} ${t(err.message)}`);
      }
    };

    getGroups();
  }, [setGroups, t, backendClient]);

  return <Groups
    groups={groups}
    error={error}
    setError={setError}
    isAdmin={auth.isAdmin()}
    isAuthenticated={auth.isAuthenticated()}
    redirectUrl={redirectUrl}
    setRedirectUrl={setRedirectUrl}
  />;
}
