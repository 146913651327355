import React, { useContext, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import Select from "react-select";

import backendClient from 'clients/backendClient';
import Spinner from 'components/Spinner';
import Translation from 'locales/Translation';
import AuthContext from 'contexts/Auth';

import './styles.scss';

const { useTranslation } = Translation.setup();

const RETURN_KEY = 13;

const USER_TYPES = [
  {
    value: 'member',
    label: 'Member',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
];

export function InviteUser(props) {
  const {
    inviteUser,
    emailAddress,
    setEmailAddress,
    userType,
    setUserType,
    submitButtonDisabled,
    updateSubmitButtonEnabledness,
    userBeingInvited,
    isAdmin,
    t,
  } = props;

  if (userBeingInvited) {
    return <Spinner />;
  }

  const onSubmitClicked = async () => {
    if (emailAddress && emailAddress.value) {
      const result = await inviteUser(emailAddress.value, userType);
      if (result && result.userId) {
        emailAddress.value = '';
        updateSubmitButtonEnabledness();
      }
    }
  };

  const onKeyUp = async (event) => {
    if (event.charCode === RETURN_KEY) {
      onSubmitClicked(event);
    }
  };

  return (
    <React.Fragment>
      <InputGroup className="regenagri-invite-user-form">
        <FormControl
          placeholder={t("Email Address")}
          aria-label="EmailAddress"
          data-testid="email-address"
          ref={(ref) => setEmailAddress(ref)}
          onChange={updateSubmitButtonEnabledness}
          onKeyPress={onKeyUp}
        />
        {isAdmin && (
          <div className="regenagri-user-type-select">
            <Select
              options={USER_TYPES}
              value={USER_TYPES.find(type => type.value === userType)}
              onChange={(selected) => setUserType(selected.value)}
            />
          </div>
        )}
        <Button
          disabled={submitButtonDisabled}
          onClick={onSubmitClicked}
        >
          {t("Invite")}
        </Button>
      </InputGroup>
    </React.Fragment>
  );
}

export default function InviteUserWrapper(props) {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const {
    setSuccess,
    setError,
    groupId,
  } = props;

  const [emailAddress, setEmailAddress] = useState(null);
  const [userType, setUserType] = useState('member');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [userBeingInvited, setUserBeingInvited] = useState(false);

  const updateSubmitButtonEnabledness = () => {
    if (emailAddress && emailAddress.value) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const inviteUser = async (emailAddress, userType) => {
    let result;
    setUserBeingInvited(true);
    try {
      const { userId } = await backendClient.inviteUser(groupId, emailAddress, userType === 'admin');
      setSuccess(`${t("Invite sent to")} ${emailAddress}`);
      result = {
        userId,
        emailAddress,
      };
    } catch (err) {
      setError(`${t("Unable to invite user -")} ${t(err.message)}`);
    }
    setUserBeingInvited(false);
    return result;
  };

  return <InviteUser
    inviteUser={inviteUser}
    emailAddress={emailAddress}
    setEmailAddress={setEmailAddress}
    userType={userType}
    setUserType={setUserType}
    submitButtonDisabled={submitButtonDisabled}
    updateSubmitButtonEnabledness={updateSubmitButtonEnabledness}
    userBeingInvited={userBeingInvited}
    isAdmin={auth.isAdmin()}
    t={t}
  />;
}
