import React, { useContext, useEffect, useState } from 'react';
import qs from 'qs';
import { Alert } from 'react-bootstrap';

import { RedirectContext } from 'contexts/Routing';
import BackendClientContext from 'contexts/BackendClient';
import Spinner from 'components/Spinner';

export function VerifyUserAndGroupInvitation(props) {
  const {
    verificationSuccess,
    error,
    token,
  } = props;

  const Redirect = useContext(RedirectContext);

  if (error) {
    return (
      <Alert variant="danger">{error}</Alert>
    );
  }

  if (verificationSuccess) {
    return <Redirect to={`/verify-email/${token}`} />;
  }
  return <Spinner />;
}

export default function VerifyUserAndGroupInvitationWrapper(props) {
  const { location } = props;
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    groupId,
    userId,
    token,
  } = params;

  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [error, setError] = useState(null);

  const backendClient = useContext(BackendClientContext);

  useEffect(() => {
    const verifyInvitation = async () => {
      try {
        await backendClient.verifyInvitation(groupId, userId);
        setVerificationSuccess(true);
      } catch (err) {
        setError(`An error occurred while trying to accept the group invitation: ${err.message}`);
      }
    };

    verifyInvitation();
  }, [groupId, userId, backendClient]);

  return <VerifyUserAndGroupInvitation
    error={error}
    verificationSuccess={verificationSuccess}
    token={token}
  />;
}
