function getBackgroundColor(isFocused, isSelected) {
  if (isSelected) {
    return "#7aa098";
  }
  return (isFocused ? "#89b9af" : null);
}

export const customStyles = {
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: getBackgroundColor(isFocused, isSelected),
  }),
  control: (styles) => ({
    ...styles,
    '&:hover': { borderColor: '#d0cece' },
    '&:focus': { borderColor: '#d0cece' },
    border: '1px solid #d0cece',
    boxShadow: `none`,
  }),
};
