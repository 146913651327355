export const INITIAL_STATE = {
  formJson: null,
  successfulSubmissionResponse: null,
  pendingSubmission: false,
  submission: null,
  error: null,
  authFailed: false,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_FORM_JSON':
      return { ...state, formJson: action.value };
    case 'SET_SUCCESSFUL_SUBMISSION_RESPONSE':
      return { ...state, successfulSubmissionResponse: action.value, error: null };
    case 'SET_PENDING_SUBMISSION':
      return { ...state, pendingSubmission: action.value };
    case 'SET_SUBMISSION':
      return { ...state, submission: action.value };
    case 'SET_ERROR':
      return { ...state, error: action.value };
    case 'SET_AUTH_FAILED':
      return { ...state, authFailed: true };
    case 'SET_AUTH_SUCCESS':
      return { ...state, authFailed: false };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
