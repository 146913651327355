import React from 'react';
import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import RegenAgriForm from 'components/RegenAgriForm';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function VerifyEmailForm(props) {
  const { match } = props;
  const { t } = useTranslation();
  const existingSubmission = {
    data: {
      verificationToken: match.params.token,
    },
  };

  return (
    <div className="regenagri-small-form-box">
      <Alert variant="info">
        {t('Please enter a password to finish creating your account.')}
      </Alert>
      <RegenAgriForm
        formId={"verifyemail"}
        successMarkup={<Redirect to='/login?userVerified=true' />}
        requiresAuthentication={false}
        existingSubmission={existingSubmission}
      />
    </div>
  );
}

export default VerifyEmailForm;
