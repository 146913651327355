import React from 'react';
import { Link } from 'react-router-dom';

import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function LanguageSwitch() {
  const { t } = useTranslation();
  return (
    <span className="language-switch">
      <button className="flag-uk" onClick={() => Translation.setLanguage("en")}>
        <img src="/images/uk-flag.png" alt={t('Switch to english language')} />
      </button>
      <button className="flag-pt" onClick={() => Translation.setLanguage("pt")}>
        <img src="/images/pt-flag.png" alt={t('Switch to portuguese language')} />
      </button>
    </span>
  );
}

function NavShade({ isAuthenticated }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <nav id="regenagri-nav-shade">
        <span>
          <a href="https://regenagri.org/">
            <img className="regenagri-logo-small" src="/images/regenagri.png" alt={`${t('regenagri')}: ${t('Make your land carbon positive')}.`} />
          </a>
          {window.location.hostname === 'digitalhub-bunge.regenagri.org' && (
            <span>
              <img className="bunge-logo-small" src="/images/bunge-logo-regenagri.png" alt="bunge" />
            </span>
          )}
        </span>
        <div className="regenagri-right-nav-container">
          <LanguageSwitch />
          {isAuthenticated && (
            <Link to="/logout" className="btn btn-primary">
              {t('Log Out')}
            </Link>
          )}
        </div>
      </nav>
    </React.Fragment>
  );
}

export default NavShade;
