import React from 'react';
import { Table } from 'react-bootstrap';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

/*
 * If this thing is a number, or a number masquerading as a string,
 * then give it some comma separators. Otherwise, run it through a
 * translate function
 */
function renderPossibleNumber(value, t) {
  if (typeof value === 'number') {
    return value.toLocaleString();
  }
  if (!isNaN(value)) {
    return Number(value).toLocaleString();
  }
  return t(value);
}

/**
 * This is how we return values with units in the regenagri scorecard
 */
function isValueWithUnits(value) {
  return value instanceof Object && value.value !== undefined && value.unit;
}

/**
 * This is one of the two ways that CFT returns values with units (eg ['-3722', 'kg CO2e / kg'])
 */
function isCftValueWithUnitsAsArray(value) {
  return value instanceof Array && value.length === 2 && !isNaN(value[0]);
}

/**
 * This is one of the two ways that CFT returns values with units (eg '1600 litres')
 */
function isCftValueWithUnitsAsString(value) {
  return typeof value === 'string' && isCftValueWithUnitsAsArray(value.split(' '));
}

function renderValueWithUnits({ value, unit }, t) {
  const separator = (unit === '%') ? '' : ' ';
  return `${renderPossibleNumber(value, t)}${separator}${t(unit)}`;
}

export function formatValue(value, t = (v) => v) {
  if (typeof value === 'boolean') {
    return t(value ? "yes" : "no");
  }
  if (isValueWithUnits(value)) {
    // The regenagri style
    return renderValueWithUnits(value, t);
  }
  if (isCftValueWithUnitsAsArray(value)) {
    return formatValue({ value: Number(value[0]), unit: value[1] }, t);
  }
  if (isCftValueWithUnitsAsString(value)) {
    const splat = value.split(' ');
    return formatValue({ value: Number(splat[0]), unit: splat[1] }, t);
  }
  if (value instanceof Array) {
    return value.map(val => renderPossibleNumber(val, t)).join(', ');
  }
  return renderPossibleNumber(value, t);
}

export function formatDetails(value) {
  if (!value || !value.length) {
    return null;
  }
  return value.map(v => {
    const keys = Object.keys(v);
    if (keys.length === 2 && keys.includes('CO2e_emission')) {
      const otherKey = keys.filter(k => k !== 'CO2e_emission')[0];
      return <p key={[otherKey]}><small>{v[otherKey]}: {v.CO2e_emission}</small></p>;
    }
    return null;
  });
}

function ScorecardTableRow({ label, value, t }) {
  if (label === "disaggregation_totals") {
    return null;
  }

  let formattedValue = formatValue(value, t);
  if (label === 'details') {
    formattedValue = formatDetails(value);
  }

  return (
    <tr className="" key={label}>
      <td className="regenagri-scorecard-component-field">{t(label)}</td>
      <td className="regenagri-scorecard-component-value">{formattedValue}</td>
    </tr>
  );
}

export default function ScorecardTable({ obj }) {
  const { t } = useTranslation();
  return (
    <Table responsive striped>
      <tbody>
        { Object.entries(obj).map(([key, value]) => ((key === "name")
          ? null
          : <ScorecardTableRow label={key} value={value} t={t} key={key}/>
        ))}
      </tbody>
    </Table>
  );
}
