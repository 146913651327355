import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import auth from 'common/auth';

function Logout({ setIsAuthenticated }) {
  useEffect(() => {
    auth.clearJwt();
    setIsAuthenticated(false);
  }, [setIsAuthenticated]);

  return <Redirect to='/' />;
}

export default Logout;
