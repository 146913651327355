import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GAListener from 'common/analytics';
import auth from 'common/auth';
import Plot from 'react-plotly.js';

import AdminDashboard from 'scenes/admin/Dashboard';
import AdminAssessments from 'scenes/admin/Assessments';
import AdminGroups from 'scenes/admin/Groups';
import GroupDetails from 'scenes/admin/GroupDetails';
import Assessments from 'scenes/Assessments';
import AssessmentForm from 'scenes/createAssessment';
import AssessmentMultiForm from 'scenes/createAssessment/MultiForm';
import CombineScorecard from 'scenes/CombineScorecard';
import CompareScorecard from 'scenes/CompareScorecard';
import Farms from 'scenes/Farms';
import GroupAdminDashboard from 'scenes/groupAdmin/Dashboard';
import GroupAdminAssessments from 'scenes/groupAdmin/Assessments';
import LoginForm from 'scenes/LoginForm';
import Logout from 'scenes/Logout';
import RegisterForm from 'scenes/RegisterForm';
import RequestPasswordReset from 'scenes/RequestPasswordReset';
import Scorecard from 'scenes/Scorecard';
import VerifyEmail from 'scenes/VerifyEmail';
import ResetPassword from 'scenes/ResetPassword';
import VerifyGroupInvitation from 'scenes/VerifyGroupInvitation';
import VerifyUserAndGroupInvitation from 'scenes/VerifyUserAndGroupInvitation';
import PlotContext from 'contexts/Plot';
import NavShade from './NavShade';

import './styles.scss';

const isProductionSystem = window.location.href.includes("regenagri.org");

const redirectToHomepage = () => {
  if (isProductionSystem) {
    window.location.href = 'https://regenagri.org/';
  } else {
    window.location.href = '/login';
  }
};

const redirectToNotFound = () => {
  if (isProductionSystem) {
    window.location.href = 'https://regenagri.org/404.html';
  }
};

function AssessmentWrapper(props) {
  if (["fertilisers", "cpp", "test"].includes(props.match.params.sectionId)) {
    return <AssessmentMultiForm {...props} key={props.match.params.sectionId} />;
  }
  return <AssessmentForm {...props} key={props.match.params.sectionId} />;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());
  return (
    <Router>
      <PlotContext.Provider value={Plot}>
        <GAListener />
        <div className="main-wrapper">
          <NavShade isAuthenticated={isAuthenticated} />
          <div className="regenagri-app">
            <div className="regenagri-content">
              <Switch>
                <Route exact path="/" component={() => { redirectToHomepage(); return null; }} />
                <Route exact path="/login" component={props => <LoginForm {...props} setIsAuthenticated={setIsAuthenticated}/>}/>
                <Route exact path="/logout" component={props => <Logout {...props} setIsAuthenticated={setIsAuthenticated}/>}/>
                <Route exact path="/register" component={RegisterForm}/>
                <Route exact path="/request-password-reset" component={RequestPasswordReset}/>
                <Route exact path="/assessments" component={Assessments}/>
                <Route path="/assessments/:assessmentId/scorecard" component={Scorecard}/>
                <Route path="/assessments/scorecard/combine" component={CombineScorecard} />
                <Route path="/assessments/scorecard/compare" component={CompareScorecard} />
                <Route path="/assessments/:assessmentId/:sectionId" component={(props) => <AssessmentWrapper {...props} />} />
                <Route exact path="/create-assessment" component={Farms}/>
                <Route path="/verify-email/:token" component={VerifyEmail}/>
                <Route path="/reset-password/:token" component={ResetPassword}/>
                <Route exact path="/admin/dashboard" component={AdminDashboard}/>
                <Route exact path="/admin/assessments" component={AdminAssessments}/>
                <Route exact path="/admin/groups" component={AdminGroups}/>
                <Route exact path="/group-admin/dashboard" component={GroupAdminDashboard}/>
                <Route exact path="/group-admin/assessments" component={GroupAdminAssessments}/>
                <Route path="/groups/:groupId/verify-invitation/:userId" component={VerifyGroupInvitation}/>
                <Route path="/groups/:groupId" component={GroupDetails}/>
                <Route path="/verify-user-and-invitation" component={VerifyUserAndGroupInvitation}/>
                <Route component={() => { redirectToNotFound(); return null; }} />
              </Switch>
            </div>
          </div>
        </div>
      </PlotContext.Provider>
    </Router>
  );
}

export default App;
