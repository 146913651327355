import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { showAssessment } from 'common/assessmentFiltering';

export function AssessmentsTableGrid({
  assessments,
  selectedAssessments,
  selectedFilterData,
  mapAssessmentToTableRow,
}) {
  const { t } = useTranslation();
  return <Table responsive striped className="regenagri-assessments-table">
    <thead>
      <tr>
        <th>{t("Farm Name")}</th>
        <th>{t("Date")}</th>
        <th>{t("Last Updated")}</th>
        <th className="regenagri-assessments-completed">{t("Completed")}</th>
        <th className="regenagri-assessments-action">{t("Actions")}</th>
        <th className="regenagri-assessments-checkbox">{t("Select")}</th>
      </tr>
    </thead>
    <tbody>
      {assessments
        .sort((a, b) => (b.modified.localeCompare(a.modified)))
        .filter(
          (assessment) => selectedAssessments.has(assessment.id) || showAssessment(assessment, selectedFilterData)
        )
        .map(mapAssessmentToTableRow)}
    </tbody>
  </Table>;
}
