import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Translation from 'locales/Translation';
import { RedirectContext } from "contexts/Routing";
import { buildCombinedScorecardUrl } from "utils";

const { useTranslation } = Translation.setup();

function AreaInput(props) {
  const { label, setValue, setUnit } = props;
  function handleValueChange(e) {
    setValue(e.target.value);
  }

  function handleUnitChange(e) {
    setUnit(e.target.value);
  }

  const { t } = useTranslation();
  return (
    <Row className='regenagri-combined-area-input'>
      <Col>
        <Form.Group>
          <Form.Label>{t(label)}:</Form.Label>
          <Form.Control type="number" onChange={handleValueChange} />
        </Form.Group>
      </Col>
      <Col className="regenagri-area-unit-input">
        <Form.Group>
          <Form.Label>{t('units')}:</Form.Label>
          <Form.Control as="select" onChange={handleUnitChange}>
            <option value="hectares">{t('hectares')}</option>
            <option value="acres">{t('acres')}</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
}

function getInitialUrl(selectedAssessments) {
  return buildCombinedScorecardUrl(
    `/assessments/scorecard/combine?`,
    selectedAssessments,
    [],
    null,
    'hectares',
    null,
    'hectares',
    null,
    'hectares',
  );
}

export function getUpdatedUrl(
  selectedAssessments,
  farmNames,
  totalAreaOfLandBeingAssessed,
  totalAreaOfLandBeingAssessedUnit,
  totalAgriculturalLand,
  totalAgriculturalLandUnit,
  totalConservationArea,
  totalConservationAreaUnit,
) {
  return buildCombinedScorecardUrl(
    `/assessments/scorecard/combine?`,
    selectedAssessments,
    farmNames,
    totalAreaOfLandBeingAssessed,
    totalAreaOfLandBeingAssessedUnit,
    totalAgriculturalLand,
    totalAgriculturalLandUnit,
    totalConservationArea,
    totalConservationAreaUnit,
  );
}

export function parseFarmNames(farmNameString) {
  return farmNameString
    .split(/\n/)
    .map(name => name.trim())
    .filter(name => name);
}

export function ChooseCombinedAssessmentOptions(props) {
  const {
    cancel,
    selectedAssessments,
    show,
  } = props;
  const { t } = useTranslation();
  const Redirect = useContext(RedirectContext);

  const [url, setUrl] = useState(getInitialUrl(selectedAssessments));
  const [farmNames, setFarmNames] = useState([]);
  const [combineAssessments, setCombineAssessments] = useState(false);
  const [totalAreaOfLandBeingAssessed, setTotalAreaOfLandBeingAssessed] = useState(null);
  const [totalAreaOfLandBeingAssessedUnit, setTotalAreaOfLandBeingAssessedUnit] = useState('hectares');
  const [totalAgriculturalLand, setTotalAgriculturalLand] = useState(null);
  const [totalAgriculturalLandUnit, setTotalAgriculturalLandUnit] = useState('hectares');
  const [totalConservationArea, setTotalConservationArea] = useState(null);
  const [totalConservationAreaUnit, setTotalConservationAreaUnit] = useState('hectares');

  function handleFarmInputChange(e) {
    setFarmNames(parseFarmNames(e.target.value));
  }

  useEffect(() => setUrl(getUpdatedUrl(
    selectedAssessments,
    farmNames,
    totalAreaOfLandBeingAssessed,
    totalAreaOfLandBeingAssessedUnit,
    totalAgriculturalLand,
    totalAgriculturalLandUnit,
    totalConservationArea,
    totalConservationAreaUnit
  )), [
    farmNames,
    selectedAssessments,
    totalAreaOfLandBeingAssessed,
    totalAreaOfLandBeingAssessedUnit,
    totalAgriculturalLand,
    totalAgriculturalLandUnit,
    totalConservationArea,
    totalConservationAreaUnit,
  ]);

  if (combineAssessments && selectedAssessments?.size > 1) {
    return <Redirect push to={url} />;
  }
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{t('combined assessment options')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div><small>{t('You can replace the automatically generated values by providing your own in the form below. Overwriting total agricultural land will scale all the calculations according to the provided value.')}</small></div>
        <hr />
        <Form.Group>
          <Form.Label>{t('farm names')}:</Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t("List farms included in the scorecard, one farm per line")}
            onChange={handleFarmInputChange}
          />
        </Form.Group>
        <AreaInput
          label="Total Area Of Land Being Assessed"
          setValue={setTotalAreaOfLandBeingAssessed}
          setUnit={setTotalAreaOfLandBeingAssessedUnit}
        />
        <AreaInput
          label="Total Agricultural Land"
          setValue={setTotalAgriculturalLand}
          setUnit={setTotalAgriculturalLandUnit}
        />
        <AreaInput
          label="Total Conservation Area"
          setValue={setTotalConservationArea}
          setUnit={setTotalConservationAreaUnit}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="modal-combine-button"
          onClick={() => setCombineAssessments(true)}
          variant="primary"
        >
          {t('Combine')}
        </Button>
        <Button
          data-testid="modal-cancel-button"
          onClick={() => cancel()}
          variant="secondary"
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal >
  );
}

export default function ChooseCombinedAssessmentOptionsWrapper(props) {
  const { selectedAssessments, show, setShow } = props;
  const [error, setError] = useState(null);
  const hideModal = () => {
    setError(null);
    setShow(false);
  };

  return (
    <React.Fragment>
      <ChooseCombinedAssessmentOptions
        error={error}
        setError={setError}
        cancel={() => hideModal()}
        selectedAssessments={selectedAssessments}
        show={show}
      />
    </React.Fragment>
  );
}
