import React, { useEffect, useContext, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import config from 'common/config';
import Spinner from 'components/Spinner';
import { getPreviousItem } from 'common/utils';
import AuthContext from 'contexts/Auth';
import BackendClientContext from 'contexts/BackendClient';
import RegenAgriFormContext from 'contexts/RegenAgriForm';
import { RedirectContext } from 'contexts/Routing';
import Translation from 'locales/Translation';

import "./styles.scss";
import { RedirectModal } from './RedirectModal';
import {
  ASSESSMENT_FORM_TITLES,
  NextSectionButton,
  PAGE_NAVIGATION_WARNING_MESSAGE,
  getNextForm,
  isEditable,
  renderTopButtons,
} from './utils';

const { useTranslation } = Translation.setup();

function getPlantingsFromField(field) {
  const plantings = [...field.plantings];
  const plantingsWithFields = plantings.map(p => ({
    ...p,
    fieldName: field.fieldName,
  }));
  return plantingsWithFields;
}

function getPlantingRef(fieldName, crop, harvestYear) {
  if (fieldName && crop && harvestYear) {
    return `${fieldName}-${crop}-${harvestYear}`;
  }
  return null;
}

function filterRelevantItems(submissionData, sectionId, plantingRef) {
  if (plantingRef && submissionData && submissionData.data && submissionData.data[sectionId]) {
    const filtered = [];
    const additionalPayload = [];
    submissionData.data[sectionId].forEach(item => {
      if (item.plantingRef === plantingRef) {
        filtered.push(item);
      } else {
        additionalPayload.push(item);
      }
    });
    return {
      filtered,
      additionalPayload,
    };
  }
  return { filtered: [], additionalPayload: [] };
}

function PlantingSubform(props) {
  const {
    planting,
    sectionId,
    existingSubmission,
    onSuccess,
    isReadonly,
  } = props;
  const RegenAgriForm = useContext(RegenAgriFormContext);
  const { fieldName, crop, harvestYear } = planting;
  const plantingRef = getPlantingRef(fieldName, crop, harvestYear);
  const { filtered, additionalPayload } = filterRelevantItems(existingSubmission, sectionId, plantingRef);
  const modifiedSubmission = {
    ...existingSubmission,
    data: {
      ...existingSubmission.data,
      fieldName,
      crop,
      harvestYear,
      plantingRef,
    },
  };

  modifiedSubmission.data[sectionId] = filtered;

  return (
    <React.Fragment key={`${planting.fieldName}=${planting.crop}-${planting.harvestYear}`}>
      <h3>{planting.fieldName}: {planting.crop} harvested in {planting.harvestYear}</h3>
      <RegenAgriForm
        renderTopButtons={null}
        formId={`assessments/${sectionId}`}
        requiresAuthentication={true}
        onSuccess={onSuccess}
        existingSubmission={modifiedSubmission}
        submissionId={existingSubmission._id}
        isReadonly={isReadonly}
        additionalPayload={additionalPayload}
        additionalPayloadKey={sectionId}
      />
    </React.Fragment>
  );
}

export function AssessmentMultiFormSection(props) {
  const {
    assessmentFormIds,
    assessmentId,
    error,
    existingSubmission,
    isReadonly,
    redirectModalLocation,
    redirectUrl,
    sectionId,
    setRedirectModalLocation,
    setRedirectUrl,
    t,
  } = props;

  const Redirect = useContext(RedirectContext);

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (!existingSubmission || !assessmentFormIds) {
    return <Spinner />;
  }

  const plantingsPresent = existingSubmission && existingSubmission.data?.plantings.length;

  // e.g. if the current user is trying to access a paid-member section
  // of the assessment
  if (!assessmentFormIds.includes(sectionId)) {
    return <Redirect to="/assessments" />;
  }

  function getPreviousForm() {
    if (!assessmentFormIds.includes(sectionId)) {
      return null;
    }

    return getPreviousItem(assessmentFormIds, sectionId, null);
  }

  const previousForm = getPreviousForm();
  const title = t(ASSESSMENT_FORM_TITLES[sectionId]);

  const redirectWithModalIfRequired = (url) => {
    if (isReadonly) {
      setRedirectUrl(url);
    } else {
      setRedirectModalLocation(url);
    }
  };

  const nextForm = getNextForm(sectionId, assessmentFormIds) || "scorecard";

  const onSuccess = (event) => {
    // do not go t the next form, just reload the site
    window.location = window.location.href;

    // prevents asking if user wants to leave site multiple times
    event.stopImmediatePropagation();
  };

  const goToNextForm = () => {
    setRedirectUrl(`/assessments/${assessmentId}/${nextForm}`);
  };

  let forms = null;
  if (existingSubmission.data.plantings && existingSubmission.data.plantings.length) {
    forms = existingSubmission.data.plantings.map(planting => (
      <PlantingSubform
        planting={planting}
        sectionId={sectionId}
        existingSubmission={existingSubmission}
        onSuccess={onSuccess}
        isReadonly={isReadonly}
        key={`${planting.fieldName}-${planting.crop}-${planting.harvestYear}`}
      />
    ));
  }

  return (
    <React.Fragment>
      {
        title &&
          <Helmet>
            <title>{t('regenagri')} - { title.toLowerCase() }</title>
          </Helmet>
      }
      <Prompt
          when={true}
          message={PAGE_NAVIGATION_WARNING_MESSAGE}
        />
      <RedirectModal
        redirectModalLocation={redirectModalLocation}
        setRedirectUrl={setRedirectUrl}
        setRedirectModalLocation={setRedirectModalLocation}
        message={PAGE_NAVIGATION_WARNING_MESSAGE}
      />
      <h1>{ title }</h1>
      { error && <Alert variant="danger">{ t(error) }</Alert> }
      {renderTopButtons(previousForm, redirectWithModalIfRequired, assessmentId, t)}
      {plantingsPresent
        ? forms : (
          <Alert>
            {`There are no crops to add ${sectionId} data to. Please go to the next section`}
          </Alert>
        )}
      <NextSectionButton onSuccess={goToNextForm} />
    </React.Fragment>
  );
}

function AssessmentMultiFormWrapper(props) {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const backendClient = useContext(BackendClientContext);

  const { match } = props;
  const id = match.params.assessmentId;
  const { sectionId } = match.params;

  const [existingSubmission, setExistingSubmission] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [redirectModalLocation, setRedirectModalLocation] = useState(null);
  const [error, setError] = useState(null);
  const [assessmentFormIds, setAssessmentFormIds] = useState(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [showNextSectionButton, setShowNextSectionButton] = useState(true);

  const currentUserId = auth.getUserId();

  useEffect(() => {
    const beforeUnload = (event) => event.stopImmediatePropagation();
    window.addEventListener('beforeunload', beforeUnload);
  }, []);

  useEffect(() => {
    const getAssessmentForms = async () => {
      try {
        const assessmentForms = await backendClient.getAssessmentFormIdsAsync(id);
        setAssessmentFormIds(assessmentForms);
      } catch (err) {
        setError(t('Unexpected error: Could not retrieve assessment form list'));
      }
    };

    getAssessmentForms();
  }, [backendClient, id, t]);

  const getPlantings = async (assessmentId) => {
    try {
      const result = await backendClient.getAssessmentSubmission(config.fieldsFormId, assessmentId);
      const { fields } = result.data;
      const plantings = fields.map(f => getPlantingsFromField(f)).flat();
      const processedPlantings = plantings.map(p => ({
        fieldName: p.fieldName,
        crop: p.crop.label,
        harvestYear: p.harvestDateYear.label,
      }));
      return processedPlantings;
    } catch (err) {
      return false;
    }
  };

  const augmentSubmissionWithRequiredFlags = async (submission) => {
    const augmentedSubmission = { ...submission };
    augmentedSubmission.data.plantings = await getPlantings(id);
    return augmentedSubmission;
  };

  // this effect should only run after assessmentFormIds have been retrieved
  useEffect(() => {
    const setReadOnlyState = async () => {
      const { assessment } = await backendClient.getAssessment(id);
      setIsReadonly(!isEditable(assessment, currentUserId));
    };

    setReadOnlyState();

    const getAssessmentSubmission = async () => {
      try {
        const submission = await backendClient.getAssessmentSubmission(sectionId, id);
        const augmentedSubmission = await augmentSubmissionWithRequiredFlags(submission);
        setExistingSubmission(augmentedSubmission);
      } catch (err) {
        const data = { assessmentId: id };
        if (sectionId === config.profileInformationFormId) {
          const { farm } = await backendClient.getFarmForAssessment(id);
          if (farm) {
            data.isFarmLinkedAssessment = true;
            data.farmNameLabel = farm.name;
          }
        }
        const augmentedSubmission = await augmentSubmissionWithRequiredFlags({ data });
        setExistingSubmission(augmentedSubmission);
      }
    };

    if (!existingSubmission && assessmentFormIds) {
      getAssessmentSubmission();
    }
  }, [assessmentFormIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Prompt the user before refreshing
    window.onbeforeunload = () => PAGE_NAVIGATION_WARNING_MESSAGE;
    return () => {
      // Remove prompt when navigating away from the page
      window.onbeforeunload = undefined;
    };
  }, []);

  useEffect(() => {
    const setEnablednessForNextSection = async () => {
      const { assessment } = await backendClient.getAssessment(id);
      setShowNextSectionButton(assessment.data.completed || assessment.data.lastModifiedSection !== sectionId);
    };
    if (isReadonly) {
      setEnablednessForNextSection();
    }
  }, [isReadonly, sectionId, id, backendClient]);

  return (
    <AssessmentMultiFormSection
      assessmentFormIds={assessmentFormIds}
      assessmentId={id}
      error={error}
      existingSubmission={existingSubmission}
      redirectModalLocation={redirectModalLocation}
      redirectUrl={redirectUrl}
      sectionId={sectionId}
      setRedirectModalLocation={setRedirectModalLocation}
      setRedirectUrl={setRedirectUrl}
      isReadonly={isReadonly}
      showNextSectionButton={showNextSectionButton}
      t={t}
    />
  );
}

export default AssessmentMultiFormWrapper;
