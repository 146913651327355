import jwtDecode from 'jwt-decode';

function getJwt() {
  return window.localStorage.getItem("jwt");
}

function isAdmin() {
  return (
    isAuthenticated() && window.localStorage.getItem("isAdmin") === "true"
  );
}

function isGroupAdmin() {
  return (
    isAuthenticated() && window.localStorage.getItem("isGroupAdmin") === "true"
  );
}

function getGroupId() {
  return (
    isAuthenticated() && window.localStorage.getItem("groupId")
  );
}

function getGroupName() {
  return (
    isAuthenticated() && window.localStorage.getItem("groupName")
  );
}

function setJwt(value, userIsAdmin, userIsGroupAdmin, groupId, groupName) {
  window.localStorage.setItem("jwt", value);
  window.localStorage.setItem("isAdmin", userIsAdmin);
  window.localStorage.setItem("isGroupAdmin", userIsGroupAdmin);
  window.localStorage.setItem("groupId", groupId || "");
  window.localStorage.setItem("groupName", groupName);
}

function isAuthenticated() {
  const token = getJwt();
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  return Date.now() < decoded.exp * 1000;
}

function getUserId() {
  if (!isAuthenticated()) {
    return undefined;
  }
  const token = getJwt();
  const decoded = jwtDecode(token);
  return decoded.user._id;
}

function clearJwt() {
  window.localStorage.removeItem("jwt");
  window.localStorage.removeItem("isAdmin");
  window.localStorage.removeItem("isGroupAdmin");
  window.localStorage.removeItem("groupId");
  window.localStorage.removeItem("groupName");
}

const exports = {
  getJwt,
  setJwt,
  isAuthenticated,
  isAdmin,
  isGroupAdmin,
  getGroupId,
  getGroupName,
  clearJwt,
  getUserId,
};
export default exports;
