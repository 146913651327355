import React from 'react';
import { Table } from 'react-bootstrap';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function DisaggregationTotalsTableRow({ data, gasId }) {
  return (
    <tr>
      <td>{ gasId }</td>
      <td>{data[`metric_tonnes_${gasId}`][0]} {data[`metric_tonnes_${gasId}`][1]}</td>
      <td>{data.metric_tonnes_CO2e[0]} {data.metric_tonnes_CO2e[1]}</td>
    </tr>
  );
}

export default function DisaggregationTotalsTable({ obj }) {
  const { t } = useTranslation();
  if (obj === undefined) {
    return null;
  }
  const data = obj[0];
  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th style={{ textTransform: 'none' }}>{t("GHG")}</th>
          <th>{t("Mass")}</th>
          <th style={{ textTransform: 'none' }}>{t("CO2 equivalent")}</th>
        </tr>
      </thead>
      <tbody>
        <DisaggregationTotalsTableRow data={data.CO2} gasId="CO2" />
        <DisaggregationTotalsTableRow data={data.N2O} gasId="N2O" />
        <DisaggregationTotalsTableRow data={data.CH4} gasId="CH4" />
      </tbody>
    </Table>
  );
}
