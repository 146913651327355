import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';

export function BackToAssessmentsButton(props) {
  const {
    setRedirectUrl,
    t,
  } = props;

  return (
    <Button
        data-testid="back-to-assessments"
        onClick={() => setRedirectUrl(window.localStorage.getItem("assessmentsRefUrl"))}
    >
      {t('Back To Assessments')}
    </Button>
  );
}

export default function BackToAssessmentsButtonWrapper(props) {
  const { t } = props;

  return <BackToAssessmentsButton
    setRedirectUrl={useHistory().push}
    t={t}
  />;
}
