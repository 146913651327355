import i18n from "i18next";
import { initReactI18next, useTranslation, Trans } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import flatpickr from "flatpickr";
import moment from 'moment';
import translationEN from './en.json';
import translationPT from './pt.json';
import chemicals from './chemicals.json';
import systemValues from './system.json';

import 'moment/locale/pt';
import 'flatpickr/dist/l10n/pt';

let _formioforms = [];

function registerFormioForm(formInstance) {
  _formioforms = [..._formioforms.filter(f => f), formInstance];
}

function mapHTML(translations) {
  // It is possible to map things like <bold> to <strong> in i18n <Trans> components.
  // But we can't control that use inside FormIO, whereas we can allow some HTML to be output.
  // string.replaceAll is available in all supported browsed, but not in Node (where our tests run!)
  const substitutions = [
    [/<bold>/g, '<strong>'],
    [/<\/bold>/g, '</strong>'],
  ];

  const substitute = value => substitutions.reduce((acc, [s, r]) => acc.replace(s, r), value);

  return Object.fromEntries(
    Object.entries(translations).map(([key, value]) => [key, substitute(value)])
  );
}

function defaultResources() {
  return {
    en: { translation: mapHTML({ ...systemValues, ...chemicals, ...translationEN }) },
    pt: { translation: mapHTML({ ...systemValues, ...chemicals, ...translationPT }) },
  };
}

function currentResources() {
  return (i18n.isInitialized ? i18n.options.resources : null);
}

function _determineResources(specifiedResources) {
  return specifiedResources || currentResources() || defaultResources();
}

function _shouldInitialise(resources, i18n) {
  return JSON.stringify(resources) !== JSON.stringify(i18n.options.resources);
}

function setLanguage(lng) {
  i18n.changeLanguage(lng);
  moment.locale(lng);
  flatpickr.localize(flatpickr.l10ns[lng]);
  _formioforms.forEach(f => f && f.triggerRedraw());
}

function getCurrentLanguage() {
  return i18n.language || localStorage.i18nextLng;
}

function setup(specifiedResources) {
  const resources = _determineResources(specifiedResources, i18n);
  if (_shouldInitialise(resources, i18n)) {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources,
        fallbackLng: "en",
        debug: false,

        ns: ["translation"],
        defaultNS: "translation",
        keySeparator: false,
        interpolation: {
          escapeValue: false,
        },
      });

    const lng = getCurrentLanguage();
    setLanguage(lng);
  }

  return { useTranslation, Trans };
}

const exports = {
  setup,
  currentResources,
  getCurrentLanguage,
  setLanguage,
  registerFormioForm,
};
export default exports;
