import React from 'react';

import Translation from 'locales/Translation';
import BackToAssessmentsButton from 'components/BackToAssessmentsButton';
import { getScoreColour } from 'common/scorecard';

import FarmDetails from './FarmDetails';
import ScorecardTable from './ScorecardTable';
import { calculateFarmSummary } from './utils';

const { useTranslation, Trans } = Translation.setup();

function ScorecardSummaryText({ scorecardResult }) {
  const { scoreAsPercentage, averageScorePercentage } = scorecardResult;
  const differenceFromAverage = scoreAsPercentage - averageScorePercentage;
  let i18nKey;
  if (differenceFromAverage < 0) {
    i18nKey = "resultLowerScore";
  } else if (differenceFromAverage > 0) {
    i18nKey = "resultHigherScore";
  } else {
    i18nKey = "resultAverageScore";
  }
  const values = {
    score: scoreAsPercentage,
    average: averageScorePercentage,
    difference: Math.abs(differenceFromAverage),
  };
  return (
    <p>
      <Trans
        i18nKey={i18nKey}
        components={{ bold: <strong /> }}
        values={values}
      />
    </p>
  );
}

export default function ScorecardHeader({ scorecardResult }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="regenagri-scorecard-top-buttons">
        <BackToAssessmentsButton t={t} />
      </div>
      <FarmDetails farmDetails={scorecardResult.farmDetails} />
      <ScorecardTable obj={calculateFarmSummary(scorecardResult)}/>
      <div style={{ backgroundColor: getScoreColour(scorecardResult.scoreAsPercentage) }} className={`regenagri-scorecard-total`}>
        <div>
          {t('Total Score')}
        </div>
        <div className="regenagri-scorecard-total-score-values">
          { scorecardResult.scoreAsPercentage }%
          <div className="regenagri-scorecard-total-score-points">
            ({t('pointsOutOfTotalWithCount', { count: scorecardResult.totalScore, total: scorecardResult.maxTotalScore })})
          </div>
        </div>
      </div>
      <div className="regenagri-scorecard-section regenagri-scorecard-summary">
        <div className="regenagri-scorecard-summary-text">
          <ScorecardSummaryText scorecardResult={scorecardResult} />
          <p>
            {t('scoreCardHighlightedAreas')}
          </p>
        </div>
        <div>
          <div className="regenagri-scorecard-graph" dangerouslySetInnerHTML={{ __html: scorecardResult.scoreDistribution }} />
          <div className="regenagri-scorecard-graph-text">
            <p className="regenagri-scorecard-your-score-text">
              {t('The orange vertical line indicates your score compared to other farms')}
            </p>
            <p className="regenagri-scorecard-average-score-text">
              {t('The blue vertical line indicates average score across all farms on the system')}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
