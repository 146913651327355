const config = {
  googleAnalyticsID: 'UA-167064086-1',
  profileInformationFormId: "profileinformation",
  fieldsFormId: "fields",
  fertilisersFormId: "fertilisers",
  cppFormId: "cpp",
  livestockFormId: "livestock",
  energyFormId: "energy",
  firstAssessmentFormId: "profileinformation",
};

export default config;
