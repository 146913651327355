export function setAuthFailed() {
  return { type: "SET_AUTH_FAILED" };
}

export function setAuthSuccess() {
  return { type: "SET_AUTH_SUCCESS" };
}

export function setError(error) {
  return { type: "SET_ERROR", value: error };
}

export function setFormJson(formJson) {
  return { type: "SET_FORM_JSON", value: formJson };
}

export function setPendingSubmission(pending) {
  return { type: "SET_PENDING_SUBMISSION", value: pending };
}

export function setSubmission(submission) {
  return { type: "SET_SUBMISSION", value: submission };
}

export function setSuccessfulSubmissionResponse(response) {
  return { type: "SET_SUCCESSFUL_SUBMISSION_RESPONSE", value: response };
}
