import React from 'react';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function getFarmTypes(farmDetails, t) {
  const farmTypes = Object.entries(farmDetails.typeOfFarm)
    .filter(([key, value]) => value)
    .map(([key, value]) => key);
  return `(${farmTypes.map(t).join(", ")})`;
}

function FarmProfileDetails({ farmDetails }) {
  const { t } = useTranslation();
  if (farmDetails.typeOfFarm) {
    return (
      <div className='regenagri-farm-profile-details'>
        <h2>{farmDetails.farmName} {getFarmTypes(farmDetails, t)}</h2>
        <h4>{t(farmDetails.country)}</h4>
      </div>
    );
  }
  return <div><h2 className='regenagri-overriden'>{farmDetails.farmName}</h2></div>;
}

export default function FarmDetails({
  farmDetails,
}) {
  if (Array.isArray(farmDetails)) {
    return (
      <React.Fragment>
        {farmDetails.map(farm => <FarmProfileDetails farmDetails={farm} key={farm.farmName}/>)}
      </React.Fragment>
    );
  }

  return (
    <FarmProfileDetails farmDetails={farmDetails} />
  );
}
