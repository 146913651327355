import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import qs from 'qs';

import AssessmentsTable from 'components/AssessmentsTable';
import AuthContext from "contexts/Auth";
import BackendClientContext from 'contexts/BackendClient';
import { RedirectContext } from 'contexts/Routing';
import Translation from 'locales/Translation';

import "./styles.scss";

const { useTranslation } = Translation.setup();

function TopButtons({ setRedirectUrl }) {
  const { t } = useTranslation();
  return (
    <div className="regenagri-admin-assessments-top-buttons">
      <Button
        onClick={() => setRedirectUrl(`/admin/dashboard`)}
      >
        {t('Back To Dashboard')}
      </Button>
    </div>
  );
}

export function CreateCertifiedAssessmentButton({
  setRedirectUrl,
  userId,
}) {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="create-certified-assessment-button"
      className="create-certified-assessment-button"
      onClick={() => setRedirectUrl(`/create-assessment?userId=${userId}`)}
      disabled={false}>
      {t("Start a new assessment")}
    </Button>
  );
}

export function Assessments(props) {
  const {
    assessments,
    assessmentPath,
    setAssessmentPath,
    error,
    setError,
    isAdmin,
    isAuthenticated,
    redirectUrl,
    setRedirectUrl,
    userDisplayName,
    userId,
    patchAssessment,
    t,
  } = props;

  const Redirect = useContext(RedirectContext);

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (assessmentPath) {
    return <Redirect push to={`/assessments/${assessmentPath.assessmentId}/${assessmentPath.sectionId}`} />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login?authRequired=true" />;
  }

  if (!isAdmin) {
    return <Redirect to="/assessments" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')} - {t('admin assessments')}</title>
      </Helmet>
      <TopButtons setRedirectUrl={setRedirectUrl} />
      <h1>{t('Assessments')}</h1>
      { userDisplayName && <h4 data-testid="userDisplayName">{userDisplayName}</h4> }
      <CreateCertifiedAssessmentButton setRedirectUrl={setRedirectUrl} userId={userId}/>
      { error && <Alert variant="danger">{error}</Alert>}
      <AssessmentsTable
        assessments={assessments}
        setAssessmentPath={setAssessmentPath}
        patchAssessment={patchAssessment}
        setError={setError}
      />
    </React.Fragment>
  );
}

export default function AssessmentsWrapper(props) {
  const auth = useContext(AuthContext);
  const backendClient = useContext(BackendClientContext);
  const { t } = useTranslation();

  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const { userId } = params;

  const [assessments, setAssessments] = useState(null);
  const [assessmentPath, setAssessmentPath] = useState(null);
  const [error, setError] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState("");
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const getAssessments = async () => {
      try {
        const { assessments: receivedAssessments } = await backendClient.getAssessments(userId);
        setAssessments(receivedAssessments);
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve assessments:")} ${t(err.message)}`);
      }
    };

    getAssessments();
  }, [setAssessments, t, backendClient, userId]);

  useEffect(() => {
    const getUserById = async () => {
      try {
        const { user } = await backendClient.getUserById(userId);
        const { email } = user.data;
        setUserDisplayName(email);
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve user details:")} ${t(err.message)}`);
      }
    };
    userId ? getUserById() : setUserDisplayName(null);
  }, [t, backendClient, userId]);

  const patchAssessment = (assessmentId, patch) => {
    const newAssessments = assessments.map(assessment => {
      if (assessment.id === assessmentId) {
        return {
          ...assessment,
          ...patch,
        };
      }
      return assessment;
    });
    setAssessments(newAssessments);
  };

  return <Assessments
    assessments={assessments}
    assessmentPath={assessmentPath}
    setAssessmentPath={setAssessmentPath}
    error={error}
    isAdmin={auth.isAdmin()}
    isAuthenticated={auth.isAuthenticated()}
    redirectUrl={redirectUrl}
    setRedirectUrl={setRedirectUrl}
    userDisplayName={userDisplayName}
    patchAssessment={patchAssessment}
    setError={setError}
    userId={userId}
    t={t}
  />;
}
