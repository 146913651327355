import { createBrowserHistory } from 'history';
import qs from 'qs';

export const history = createBrowserHistory();

export function getQueryParam(name, url = window.location.href) {
  return qs.parse(url.split('?')[1])[name];
}

export function getNewLinkFromChangedParam(name, value) {
  const params = qs.parse(window.location.href.split('?')[1]);
  if (params[name] !== undefined && !value) {
    delete params[name];
  }
  if (value) {
    params[name] = value;
  }

  let newHash = createBrowserHistory().location.pathname;
  newHash = newHash.split('?');
  return `${newHash[0]}?${qs.stringify(params, { encodeValuesOnly: true })}`;
}

export function setQueryParam(name, value) {
  history.push(getNewLinkFromChangedParam(name, value));
}

// export const clearQuery = (...queryNames) => {
//   const location = Object.assign({}, history);
//   queryNames.forEach(q => delete location.query[q]);
//   history.push(location);
// };
