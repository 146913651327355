import React, {
  useEffect,
  useReducer,
  useState,
  useContext,
} from 'react';
import { Alert } from 'react-bootstrap';
import { FormContext } from 'contexts/Formio';
import { RedirectContext } from 'contexts/Routing';
import { Link } from 'react-router-dom';
import Spinner from 'components/Spinner';
import AuthContext from 'contexts/Auth';
import Translation from 'locales/Translation';
import BackendClientContext from 'contexts/BackendClient';
import { reducer, INITIAL_STATE } from './reducer';
import {
  setAuthFailed,
  setError,
  setFormJson,
  setPendingSubmission,
  setSubmission,
  setSuccessfulSubmissionResponse,
} from './actions';

import 'formiojs/dist/formio.form.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles.scss';

const RETURN_KEY = 13;
const COMPONENT_TYPES_OVERRIDING_ENTER = ["textarea"];

export function RegenAgriForm(props) {
  const {
    error,
    formioInstance,
    formJson,
    isAuthenticated,
    isReadonly,
    onKeyListenerAdded, // This exists to aid testabilit
    pendingSubmission,
    renderTopButtons,
    requiresAuthentication,
    setFormioInstance,
    setShouldSubmitWithReturnKey,
    shouldSubmitWithReturnKey,
    submission,
    submitPayload,
    successfulSubmissionResponse,
    successMarkup,
  } = props;

  const Form = useContext(FormContext);

  const { Trans } = Translation.setup();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // This is to submit the form when the return key is pressed.
    // This relies on the CustomFormioForm class to be passed into
    // the react-formio Form component.
    if (formioInstance) {
      const submitForm = (event) => {
        if (event.keyCode === RETURN_KEY && shouldSubmitWithReturnKey) {
          formioInstance.submit();
        }
      };
      document.addEventListener("keydown", submitForm);

      if (onKeyListenerAdded) {
        onKeyListenerAdded();
      }

      // Clean-up hook for when the component unmounts
      return () => {
        document.removeEventListener("keydown", submitForm);
      };
    }
  }, [formioInstance, shouldSubmitWithReturnKey, onKeyListenerAdded]);

  const onFocus = event => {
    if (!COMPONENT_TYPES_OVERRIDING_ENTER.includes(event.component.type)) {
      setShouldSubmitWithReturnKey(true);
    }
  };

  let body;
  let formIsDisplaying = false;

  if (requiresAuthentication && !isAuthenticated && error) {
    body = (
      <Link to="/">Return to login page</Link>
    );
  } else if (formJson === null || pendingSubmission) {
    body = <Spinner />;
  } else if (successfulSubmissionResponse) {
    body = successMarkup;
  } else {
    const translations = Translation.currentResources();
    const i18n = Object.keys(translations).reduce((acc, language) => {
      acc[language] = translations[language].translation;
      return acc;
    }, {});
    formIsDisplaying = true;
    const options = {
      language: Translation.getCurrentLanguage(),
      i18n,
      readOnly: isReadonly,
    };
    body = (
      <Form
        form={formJson}
        onSubmit={submitPayload}
        submission={submission}
        onFocus={onFocus}
        onBlur={() => setShouldSubmitWithReturnKey(false)}
        // After form instance mounts, add a callback to set the formioInstance
        // state
        ref={c => c && c.instance && c.instance.ready
          .then(setFormioInstance)
          .then(Translation.registerFormioForm(formioInstance))
        }
        options={options}
      />
    );
  }

  return (
    <React.Fragment>
      {
        error && <Alert variant='danger'><Trans i18nKey={error} components={{ mail: <a href='mailto:info@regenagri.org'>info@regenagri.org</a> }} /></Alert>
      }
      {
        formIsDisplaying
          && renderTopButtons
          && <div className='regenagri-top-buttons'>{ renderTopButtons() }</div>
      }
      { body }
    </React.Fragment>
  );
}

const getFormData = async (formId, requiresAuthentication, dispatch, backendClient) => {
  try {
    const data = await backendClient.getForm(formId, requiresAuthentication);
    dispatch(setFormJson(data));
  } catch {
    dispatch(setError('An error occurred while trying to get the form.'));
  }
};

const submitPayload = async (
  formId,
  requiresAuthentication,
  dispatch,
  payload,
  submissionId,
  backendClient,
  isReadonly,
  additionalPayload,
  additionalPayloadKey,
) => {
  if (additionalPayload && additionalPayloadKey) {
    payload.data[additionalPayloadKey].push(...additionalPayload);
  }
  dispatch(setPendingSubmission(true));
  dispatch(setSubmission(payload));

  try {
    let result = true;
    if (!isReadonly) {
      result = await postOrPutPayload(formId, payload, requiresAuthentication, submissionId, backendClient);
    }
    dispatch(setSuccessfulSubmissionResponse(result));
  } catch (err) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setPendingSubmission(false));
  }
};

const postOrPutPayload = async (formId, payload, requiresAuthentication, submissionId, backendClient) => {
  if (submissionId) {
    return backendClient.putForm(formId, submissionId, payload, requiresAuthentication);
  }
  return backendClient.postForm(formId, payload, requiresAuthentication);
};

export function RegenAgriFormWrapper({
  formId,
  isReadonly,
  submissionId,
  onSuccess,
  successMarkup,
  requiresAuthentication,
  existingSubmission,
  renderTopButtons,
  additionalPayload,
  additionalPayloadKey,
}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [formioInstance, setFormioInstance] = useState(null);
  const [shouldSubmitWithReturnKey, setShouldSubmitWithReturnKey] = useState(false);

  const Redirect = useContext(RedirectContext);

  const auth = useContext(AuthContext);
  const isAuthenticated = auth.isAuthenticated();
  const backendClient = useContext(BackendClientContext);

  useEffect(() => {
    if (state.successfulSubmissionResponse && onSuccess) {
      onSuccess(state.successfulSubmissionResponse);
    }
  }, [state, onSuccess]);

  useEffect(() => {
    if (requiresAuthentication && !isAuthenticated) {
      dispatch(setError("You need to be logged in to view this form"));
      dispatch(setAuthFailed());
    } else {
      getFormData(formId, requiresAuthentication, dispatch, backendClient).then(() => {
        if (existingSubmission) {
          dispatch(setSubmission(existingSubmission));
        }
      });
    }
  }, [formId, requiresAuthentication, isAuthenticated, existingSubmission, backendClient]);

  if (state.authFailed) {
    return <Redirect to='/login?authRequired=true' />;
  }

  return <RegenAgriForm
    {...state}
    isReadonly={isReadonly}
    formioInstance={formioInstance}
    renderTopButtons={renderTopButtons}
    requiresAuthentication={requiresAuthentication} isAuthenticated={isAuthenticated}
    setFormioInstance={setFormioInstance}
    setShouldSubmitWithReturnKey={setShouldSubmitWithReturnKey}
    shouldSubmitWithReturnKey={shouldSubmitWithReturnKey}
    submitPayload={payload => submitPayload(
      formId,
      requiresAuthentication,
      dispatch,
      payload,
      submissionId,
      backendClient,
      isReadonly,
      additionalPayload,
      additionalPayloadKey,
    )}
    successMarkup={successMarkup}
  />;
}

export default RegenAgriFormWrapper;
