import React, { useState } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';

import backendClient from 'clients/backendClient';
import Spinner from 'components/Spinner';
import Translation from 'locales/Translation';

import './styles.scss';

const { useTranslation } = Translation.setup();

const RETURN_KEY = 13;

export function AddFarm(props) {
  const {
    createFarm,
    farmName,
    setFarmName,
    submitButtonDisabled,
    handleInputChange,
    onNewFarmAdded,
    farmBeingCreated,
    t,
  } = props;

  if (farmBeingCreated) {
    return <Spinner />;
  }

  const onSubmitClicked = async () => {
    if (farmName && farmName.value) {
      const newFarmId = await createFarm(farmName.value);
      if (newFarmId) {
        onNewFarmAdded({
          id: newFarmId,
          name: farmName.value,
        });
        farmName.value = '';
        handleInputChange();
      }
    }
  };

  const onKeyUp = async (event) => {
    if (event.charCode === RETURN_KEY) {
      onSubmitClicked(event);
    }
  };

  return (
    <React.Fragment>
      <div className="divider" />
      <InputGroup className="regenagri-add-farm-form">
        <FormControl
          placeholder={t("Farm Name")}
          aria-label="Farmname"
          data-testid="farm-name"
          ref={(ref) => setFarmName(ref)}
          onChange={handleInputChange}
          onKeyPress={onKeyUp}
        />
        <Button
          disabled={submitButtonDisabled}
          onClick={onSubmitClicked}
        >
          {t("Create Farm")}
        </Button>
      </InputGroup>
    </React.Fragment>
  );
}

export default function AddFarmWrapper(props) {
  const { t } = useTranslation();

  const { setError, onNewFarmAdded, userId } = props;

  const [farmName, setFarmName] = useState(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [farmBeingCreated, setFarmBeingCreated] = useState(false);

  const handleInputChange = () => {
    if (farmName && farmName.value) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const createFarm = async (newFarmName) => {
    let result;
    setFarmBeingCreated(true);
    try {
      const { id } = await backendClient.createFarm(newFarmName, userId);
      result = id;
    } catch (err) {
      setError(`${t("Unable to create farm:")} ${t(err.message)}`);
    }
    setFarmBeingCreated(false);
    return result;
  };

  return <AddFarm
    farmName={farmName}
    setFarmName={setFarmName}
    createFarm={createFarm}
    submitButtonDisabled={submitButtonDisabled}
    handleInputChange={handleInputChange}
    onNewFarmAdded={onNewFarmAdded}
    farmBeingCreated={farmBeingCreated}
    t={t}
  />;
}
