import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

export const BackToLoginButton = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className='back-button'>
      <Button
        onClick={() => history.push('/login')}
        variant="primary"
      >
        {t('Back')}
      </Button>
    </div>
  );
};
