const GREEN = "rgb(2, 170, 44)";
const BLUE = "rgb(7, 163, 211)";
const ORANGE = "rgb(201, 116, 5)";
const RED = "rgb(160, 4, 4)";
const GREY = "#999";

function getScoreColour(percentage) {
  if (percentage > 75) {
    return GREEN;
  }
  if (percentage > 50) {
    return BLUE;
  }
  if (percentage > 25) {
    return ORANGE;
  }
  return RED;
}

function getComponentScoreColour(score, maxScore) {
  const percentage = score * 100 / maxScore;
  if (percentage >= 100) {
    // Percentage can exceed 100, due to bonus points
    return GREEN;
  }
  if (percentage > 66) {
    return BLUE;
  }
  if (percentage > 33) {
    return ORANGE;
  }
  return RED;
}

module.exports = {
  getComponentScoreColour,
  getScoreColour,
  RED,
  GREEN,
  GREY,
};
