import React, { useContext, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";

import SelectGroup from 'components/SelectGroup';
import BackendClientContext from 'contexts/BackendClient';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

export function ChangeAssessmentGroup(props) {
  const {
    assessmentToChangeGroupFor,
    changeAssessmentGroup,
    cancel,
    userId,
    groupIds,
    setGroupIds,
    error,
    setError,
    t,
  } = props;

  const defaultGroupIds = assessmentToChangeGroupFor && assessmentToChangeGroupFor.groups;

  return (
    <Modal show={!!assessmentToChangeGroupFor}>
      <Modal.Header>
        <Modal.Title>{t('Set Group')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        { error && <Alert variant="danger">{error}</Alert>}
        <SelectGroup
          setError={setError}
          userId={userId}
          setGroupIds={setGroupIds}
          groupIds={groupIds}
          defaultGroupIds={defaultGroupIds}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="modal-proceed-button"
          onClick={() => changeAssessmentGroup()}
          variant="primary"
        >
          {t('Set Group')}
        </Button>
        <Button
          data-testid="modal-cancel-button"
          onClick={() => cancel()}
          variant="secondary"
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ChangeAssessmentGroupWrapper(props) {
  const {
    assessmentToChangeGroupFor,
    setAssessmentToChangeGroupFor,
    userId,
    patchAssessment,
  } = props;
  const { t } = useTranslation();

  const backendClient = useContext(BackendClientContext);

  const [groupIds, setGroupIds] = useState(assessmentToChangeGroupFor && assessmentToChangeGroupFor.groups);
  const [error, setError] = useState(null);

  const hideModal = () => {
    setError(null);
    setAssessmentToChangeGroupFor(null);
  };

  const changeAssessmentGroup = async () => {
    try {
      await backendClient.changeAssessmentGroup(assessmentToChangeGroupFor.id, groupIds);
      patchAssessment(assessmentToChangeGroupFor.id, { groups: groupIds });
      hideModal();
    } catch (err) {
      setError(`${t("Unable to change assessment group:")} ${t(err.message)}`);
    }
  };

  return <ChangeAssessmentGroup
    t={t}
    assessmentToChangeGroupFor={assessmentToChangeGroupFor}
    changeAssessmentGroup={changeAssessmentGroup}
    userId={userId}
    setGroupIds={setGroupIds}
    error={error}
    setError={setError}
    cancel={() => hideModal()}
  />;
}
