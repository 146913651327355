import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import moment from 'moment';
import qs from 'qs';
import { Alert, Button, Table } from 'react-bootstrap';

import Spinner from 'components/Spinner';
import BackToAssessmentsButton from 'components/BackToAssessmentsButton';
import AuthContext from 'contexts/Auth';
import BackendClientContext from 'contexts/BackendClient';
import { RedirectContext } from 'contexts/Routing';
import PlotContext from 'contexts/Plot';
import {
  getScoreColour,
  RED,
  GREEN,
  GREY,
} from 'common/scorecard';
import Translation from 'locales/Translation';
import { Barchart } from './Barchart';

import "./styles.scss";

const { useTranslation } = Translation.setup();

function useDimensions(targetRef) {
  const getSizeOfTargetRef = () => ({
    width: targetRef.current ? targetRef.current.offsetWidth : 0,
    height: targetRef.current ? targetRef.current.offsetHeight : 0,
  });

  const [dimensions, setDimensions] = useState(getSizeOfTargetRef);

  const handleResize = () => {
    setDimensions(getSizeOfTargetRef());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    handleResize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return dimensions;
}

function ComponentComparisonGraph({
  Plot,
  t,
  containerSize,
  componentId,
  component,
}) {
  const data = component.assessmentScores.map((as, index) => (
    {
      x: [as.date],
      y: [as.score],
      name: as.farmName,
      marker: { color: getScoreColour(100 * as.score / component.maxScore) },
      type: 'bar',
      showlegend: false,
      hovertemplate: `<span style="font-size:1.8em;">${as.score}</span><br />${as.farmName}<extra></extra>`,
    }
  ));

  return <Barchart
    Plot={Plot}
    id={componentId}
    data={data}
    title={component.name}
    tickvals={component.assessmentScores.map(as => as.date)}
    ticktext={component.assessmentScores.map(as => moment(as.date).format('Do MMM YYYY'))}
    yMax={component.maxScore}
    containerSize={containerSize}
    t={t}
  />;
}

function TotalScoreComparisonGraph({
  Plot,
  t,
  scorecards,
  containerSize,
}) {
  const sortedScorecards = scorecards.sort((a, b) => (a.date.localeCompare(b.date)));
  const data = sortedScorecards.map((scorecard, index) => (
    {
      x: [scorecard.date],
      y: [scorecard.scoreAsPercentage],
      text: scorecard.farmName,
      marker: { color: getScoreColour(scorecard.scoreAsPercentage) },
      type: 'bar',
      showlegend: false,
      hovertemplate: `<span style="font-size:1.8em;">${scorecard.scoreAsPercentage}%</span><br />${scorecard.farmName}<extra></extra>`,
    }
  ));

  return <Barchart
    Plot={Plot}
    id={'totalScore'}
    data={data}
    title={'Total Score Comparison'}
    tickvals={sortedScorecards.map(sc => sc.date)}
    ticktext={sortedScorecards.map(sc => moment(sc.date).format('Do MMM YYYY'))}
    yMax={100}
    containerSize={containerSize}
    t={t}
  />;
}

function AdditionalInputValueGraph({
  Plot,
  t,
  containerSize,
  componentId,
  component,
}) {
  let tickFormat = ',d';
  if (componentId === 'carbonAssessment') {
    tickFormat = 's';
  }
  const firstAssessmentAmount = component.assessmentAmounts[0].amount;

  const _getScoreColour = (index, amount) => {
    if (index === 0) {
      return GREY;
    }
    if (amount === firstAssessmentAmount) {
      return GREY;
    }
    return amount > firstAssessmentAmount ? RED : GREEN;
  };

  const data = component.assessmentAmounts.map((as, index) => (
    {
      x: [as.date],
      y: [as.amount],
      name: as.farmName,
      marker: { color: _getScoreColour(index, as.amount) },
      type: 'bar',
      showlegend: false,
      hovertemplate: `<span style="font-size:1.8em;">${as.amount}</span><br />${as.farmName}<extra></extra>`,
    }
  ));

  return <Barchart
    Plot={Plot}
    id={componentId}
    data={data}
    title={component.name}
    tickvals={component.assessmentAmounts.map(as => as.date)}
    ticktext={component.assessmentAmounts.map(as => moment(as.date).format('Do MMM YYYY'))}
    yMax={component.assessmentAmounts}
    containerSize={containerSize}
    t={t}
    tickFormat={tickFormat}
  />;
}

function BreakdownComparisons({
  Plot,
  t,
  containerSize,
  components,
  additionalInputValues,
}) {
  return (
    <React.Fragment>
      <div className="divider" />
      <h2>{t('Score Breakdown Comparison')}</h2>
      <div className="divider" />
      { Object.entries(components).map(
        ([componentId, component]) => <ComponentComparisonGraph
          Plot={Plot}
          t={t}
          containerSize={containerSize}
          componentId={componentId}
          component={component}
          key={componentId}
        />
      ) }
      { Object.entries(additionalInputValues).map(
        ([componentId, component]) => <AdditionalInputValueGraph
          Plot={Plot}
          t={t}
          containerSize={containerSize}
          componentId={componentId}
          component={component}
          key={componentId}
        />
      ) }
    </React.Fragment>
  );
}

function ComparisonTable({
  t,
  scorecards,
}) {
  return (
    <Table responsive striped data-testid="scorecard-comparison-table">
      <thead>
        <tr>
          <th>{t('Date')}</th>
          <th>{t('Farm Name')}</th>
          <th>{t('Score As Percentage')}</th>
        </tr>
      </thead>
      <tbody>
        {
          scorecards
            .sort((a, b) => (a.date.localeCompare(b.date)))
            .map((assessment, idx) => (
              <tr key={idx}>
                <td>{ moment(assessment.date).format('Do MMMM YYYY, h:mm a') }</td>
                <td>{ assessment.farmName }</td>
                <td>{ assessment.scoreAsPercentage }</td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
}

function TopButtons({ t }) {
  return (
    <div className="regenagri-scorecard-comparison-top-buttons">
      <BackToAssessmentsButton t={t} />
    </div>
  );
}

export function CompareScorecard(props) {
  const {
    error,
    isAuthenticated,
    scorecardComparisonResult,
    showBreakdown,
    setShowBreakdown,
    containerRef,
    containerSize,
    t,
  } = props;

  const Redirect = useContext(RedirectContext);
  const Plot = useContext(PlotContext);

  if (!isAuthenticated) {
    return <Redirect push to={'/login?authRequired=true'} />;
  }

  const getBody = () => {
    if (!scorecardComparisonResult) {
      return <Spinner />;
    }
    return (
      <div className="regenagri-scorecard-comparison" ref={containerRef}>
        <h1>{t('Scorecard Comparisons')}</h1>
        <div className="divider" />
        <TopButtons t={t} />
        <TotalScoreComparisonGraph
          Plot={Plot}
          t={t}
          containerSize={containerSize}
          scorecards={scorecardComparisonResult.scorecards}
        />
        <ComparisonTable
          scorecards={scorecardComparisonResult.scorecards}
          t={t}
        />
        <div className="regenagri-scorecard-expand-button-container">
          <Button onClick={() => setShowBreakdown(!showBreakdown)}>
            {t('Show/Hide Breakdown Comparison')}
          </Button>
        </div>
        { showBreakdown &&
          <BreakdownComparisons
            Plot={Plot}
            t={t}
            containerSize={containerSize}
            components={scorecardComparisonResult.components}
            additionalInputValues={scorecardComparisonResult.additionalInputValues}
          />
        }
      </div>
    );
  };

  return (
    <React.Fragment>
      { error && <Alert variant="danger">{ error }</Alert> }
      { getBody() }
    </React.Fragment>
  );
}

export default function CompareScorecardWrapper(props) {
  const { location } = props;
  const { t } = useTranslation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { assessmentId: assessmentIdList } = params;
  const containerRef = useRef();
  const containerSize = useDimensions(containerRef);

  const backendClient = useContext(BackendClientContext);
  const auth = useContext(AuthContext);

  const [scorecardComparisonResult, setScorecardComparisonResult] = useState(null);
  const [error, setError] = useState(null);
  const [showBreakdown, setShowBreakdown] = useState(false);

  useEffect(() => {
    const getScorecardComparison = async () => {
      try {
        const result = await backendClient.getScorecardComparison(assessmentIdList);
        setScorecardComparisonResult(result);
      } catch (err) {
        setError(`${t('An error occurred whilst trying to retrieve the scorecard:')} ${t(err.message)}`);
      }
    };
    if (!scorecardComparisonResult) {
      getScorecardComparison();
    }
  }, [scorecardComparisonResult, assessmentIdList, backendClient, t]);

  return (
    <CompareScorecard
      error={error}
      isAuthenticated={auth.isAuthenticated()}
      scorecardComparisonResult={scorecardComparisonResult}
      showBreakdown={showBreakdown}
      setShowBreakdown={setShowBreakdown}
      containerRef={containerRef}
      containerSize={containerSize}
      t={t}
    />
  );
}
