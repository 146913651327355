import React, { useContext, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import AuthContext from "contexts/Auth";
import BackendClientContext from 'contexts/BackendClient';
import GroupUsersTable from 'components/GroupUsersTable';
import InviteUser from 'components/InviteUser';
import { RedirectContext } from 'contexts/Routing';

import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function TopButtons({ setRedirectUrl }) {
  const { t } = useTranslation();
  return (
    <div className="regenagri-admin-assessments-top-buttons">
      <Button
        onClick={() => setRedirectUrl(`/admin/groups`)}
      >
        {t('Back To Groups')}
      </Button>
    </div>
  );
}

export function GroupDetails(props) {
  const {
    listGroupUsers,
    groupId,
    setSuccess,
    setError,
    success,
    error,
    isAuthenticated,
    isAdmin,
    setRedirectUrl,
    redirectUrl,
  } = props;

  const Redirect = useContext(RedirectContext);
  const { t } = useTranslation();

  const setSuccessOrError = ({ success, error }) => {
    if (success) {
      setSuccess(success);
      setError("");
    } else if (error) {
      setSuccess("");
      setError(error);
    } else {
      setSuccess("");
      setError("");
    }
  };

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login?authRequired=true" />;
  }

  if (!isAdmin) {
    return <Redirect to="/assessments" />;
  }

  return (
    <>
      <Helmet>
        <title>{t('regenagri')} - {t('group')}</title>
      </Helmet>
      <TopButtons setRedirectUrl={setRedirectUrl} />
      { error && <Alert variant="danger" > { error } </Alert> }
      { success && <Alert variant="success"> { success } </Alert> }
      <InviteUser
        groupId={groupId}
        setSuccess={success => setSuccessOrError({ success })}
        setError={error => setSuccessOrError({ error })}
      />
      <GroupUsersTable
        groupId={groupId}
        listGroupUsers={listGroupUsers}
        setSuccess={success => setSuccessOrError({ success })}
        setError={error => setSuccessOrError({ error })}
      />
    </>
  );
}

export default function GroupDetailsWrapper(props) {
  const { match } = props;
  const { groupId } = match.params;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const auth = useContext(AuthContext);
  const backendClient = useContext(BackendClientContext);

  return <GroupDetails
    groupId={groupId}
    listGroupUsers={backendClient.listGroupUsers}
    error={error}
    success={success}
    setError={setError}
    setSuccess={setSuccess}
    isAdmin={auth.isAdmin()}
    isAuthenticated={auth.isAuthenticated()}
    redirectUrl={redirectUrl}
    setRedirectUrl={setRedirectUrl}
  />;
}
