/**
 * Application analytics.
 *
 * Uses Google analytics to track the page navigation and Sentry to server log
 * client side errors.
 */
import React from 'react';
import GA from 'react-ga';
import { createBrowserHistory } from 'history';
import auth from './auth';

import config from './config';

const history = createBrowserHistory();

GA.initialize(config.googleAnalyticsID);

const doesWindowHostnameInclude = str => (
  typeof (window) !== 'undefined' && window.location.hostname.includes(str)
);

export function sendEvent(category, action, label, value = null) {
  if (value) {
    GA.event({
      category,
      action,
      label,
      value,
    });
  } else {
    GA.event({
      category,
      action,
      label,
    });
  }
}

export default class GAListener extends React.Component {
  componentDidMount() {
    if (
      doesWindowHostnameInclude('regenagri.agrimetrics.co.uk')
      || doesWindowHostnameInclude('regenagri.org')
    ) {
      this.sendPageView(history.location);
      history.listen(this.sendPageView);
    }
  }

  sendPageView = location => {
    const user = auth.isAuthenticated() ? "authenticated" : "unauthenticated";
    GA.set({ page: location.pathname, user });
    GA.pageview(location.pathname);
  };

  render() {
    return null;
  }
}
