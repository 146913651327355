import React, { useState } from 'react';
import {
  Alert,
  Button,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

import backendClient from 'clients/backendClient';
import Spinner from 'components/Spinner';
import Translation from 'locales/Translation';

import './styles.scss';

const { useTranslation } = Translation.setup();

const RETURN_KEY = 13;

export function AddGroup(props) {
  const {
    createGroup,
    groupName,
    setGroupName,
    groupAdminEmailAddress,
    setGroupAdminEmailAddress,
    submitButtonDisabled,
    handleInputChange,
    onNewGroupAdded,
    success,
    groupBeingCreated,
    t,
  } = props;

  if (groupBeingCreated) {
    return <Spinner />;
  }

  const onSubmitClicked = async () => {
    if (groupName && groupName.value && groupAdminEmailAddress && groupAdminEmailAddress.value) {
      const newGroupId = await createGroup(groupName.value, groupAdminEmailAddress.value);
      if (newGroupId) {
        onNewGroupAdded({
          id: newGroupId,
          name: groupName.value,
        });
        groupName.value = '';
        groupAdminEmailAddress.value = '';
        handleInputChange();
      }
    }
  };

  const onKeyUp = async (event) => {
    if (event.charCode === RETURN_KEY) {
      onSubmitClicked(event);
    }
  };

  return (
    <React.Fragment>
      { success && <Alert variant="success">{ success }</Alert> }
      <InputGroup className="regenagri-add-group-form">
        <FormControl
          placeholder={t("Group Name")}
          aria-label="Groupname"
          data-testid="group-name"
          ref={(ref) => setGroupName(ref)}
          onChange={handleInputChange}
          onKeyPress={onKeyUp}
        />
        <FormControl
          placeholder={t("Admin Email Address")}
          aria-label="Adminemailaddress"
          data-testid="admin-email-address"
          ref={(ref) => setGroupAdminEmailAddress(ref)}
          onChange={handleInputChange}
          onKeyPress={onKeyUp}
        />
        <Button
          disabled={submitButtonDisabled}
          onClick={onSubmitClicked}
        >
          {t("Create Group")}
        </Button>
      </InputGroup>
    </React.Fragment>
  );
}

export default function AddGroupWrapper(props) {
  const { t } = useTranslation();

  const { setError, onNewGroupAdded } = props;

  const [groupName, setGroupName] = useState(null);
  const [groupAdminEmailAddress, setGroupAdminEmailAddress] = useState(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [success, setSuccess] = useState(null);
  const [groupBeingCreated, setGroupBeingCreated] = useState(false);

  const handleInputChange = () => {
    if (groupName && groupName.value && groupAdminEmailAddress && groupAdminEmailAddress.value) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const createGroup = async (newGroupName, newGroupAdminEmailAddress) => {
    let result;
    setGroupBeingCreated(true);
    try {
      const { groupId } = await backendClient.createGroup(newGroupName, newGroupAdminEmailAddress);
      setError('');
      setSuccess(`${newGroupName} ${t("created successfully")}`);
      result = groupId;
    } catch (err) {
      setSuccess('');
      setError(`${t("Unable to create group:")} ${t(err.message)}`);
    }
    setGroupBeingCreated(false);
    return result;
  };

  return <AddGroup
    groupName={groupName}
    groupAdminEmailAddress={groupAdminEmailAddress}
    setGroupName={setGroupName}
    setGroupAdminEmailAddress={setGroupAdminEmailAddress}
    createGroup={createGroup}
    submitButtonDisabled={submitButtonDisabled}
    handleInputChange={handleInputChange}
    onNewGroupAdded={onNewGroupAdded}
    success={success}
    groupBeingCreated={groupBeingCreated}
    t={t}
  />;
}
