import React, { useReducer } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';
import qs from 'qs';

import { reducer, INITIAL_STATE } from 'components/RegenAgriForm/reducer';
import RegenAgriForm from 'components/RegenAgriForm';
import auth from 'common/auth';
import Translation from 'locales/Translation';
import { setAuthSuccess } from 'components/RegenAgriForm/actions';

const { useTranslation, Trans } = Translation.setup();

function LoginForm(props) {
  const { t } = useTranslation();
  const { location, setIsAuthenticated } = props;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  let message = null;

  if (auth.isAdmin()) {
    return <Redirect to='/admin/dashboard' />;
  }

  if (auth.isAuthenticated()) {
    return <Redirect to='/assessments' />;
  }

  if (params.authRequired) {
    message = (
      <Alert variant="warning">{t("Please log in or register to start your self-assessment.")}</Alert>
    );
  } else if (params.userVerified) {
    message = (
      <Alert variant='info'>
        <Trans i18nKey="yourAccountIsVerified" components={{ bold: <b /> }} />.
      </Alert>
    );
  } else if (params.groupInvitationVerified) {
    message = (
      <Alert variant='info'>
        Group invitation successful
      </Alert>
    );
  } else if (params.passwordReset) {
    message = (
      <Alert variant='info'>
        <Trans i18nKey="yourAccountIsReset" components={{ bold: <b /> }} />.
      </Alert>
    );
  }

  const onSuccess = (response) => {
    const {
      jwt,
      isAdmin,
      isGroupAdmin,
      groupId,
      groupName,
    } = response;
    auth.setJwt(jwt, isAdmin, isGroupAdmin, groupId, groupName);
    setIsAuthenticated(true);
    if (state.authFailed) {
      dispatch(setAuthSuccess());
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')}</title>
      </Helmet>
      <div className="regenagri-login-page">
        <div className="regenagri-login-page-text">
          <p><Trans i18nKey="byloggingInYouAccess" components={{ bold: <strong /> }} /></p>
          <p><Trans i18nKey="onceComplete" components={{ bold: <strong /> }} /></p>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <p><Trans i18nKey="regenagriCertification" components={{ bold: <strong />, mail: <a href='mailto:info@regenagri.org' /> }} /></p>
        </div>
        <div className="regenagri-small-form-box">
          { message }
          <RegenAgriForm
            formId={"user/login"}
            onSuccess={onSuccess}
            requiresAuthentication={false}
          />
          <Link to='/register'><Trans i18nKey="newToRegenAgri" components={{ bold: <b /> }} /> {t('Sign up here')}.</Link>
          <br/>
          <Link to='/request-password-reset'>{t('Forgot your password?')}</Link>
        </div>
      </div>
      <div id="regenagri-terms-and-conditions-box">
        {t('copyright © 2020 regenagri. all rights reserved')}. <a href="https://regenagri.org/privacy-policy/">{t('privacy policy')}</a> / <a href="https://regenagri.org/terms-and-conditions/">{t('terms and conditions')}</a>
      </div>
    </React.Fragment>
  );
}

export default LoginForm;
