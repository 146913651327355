import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Translation from 'locales/Translation';

import "./styles.scss";

const { useTranslation } = Translation.setup();

export function RedirectModal({
  redirectModalLocation,
  setRedirectUrl,
  setRedirectModalLocation,
  message,
}) {
  const { t } = useTranslation();
  return (
    <Modal show={!!redirectModalLocation}>
      <Modal.Header>
        <Modal.Title>{t('Warning')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{t(message)}</Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="modal-proceed-button"
          onClick={() => setRedirectUrl(redirectModalLocation)}
          variant="primary"
        >
          {t('OK')}
        </Button>
        <Button
          data-testid="modal-cancel-button"
          onClick={() => setRedirectModalLocation(null)}
          variant="secondary"
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
