import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import Spinner from 'components/Spinner';
import Translation from 'locales/Translation';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';

import "./styles.scss";

const { useTranslation } = Translation.setup();
const InternalPagination = createUltimatePagination({
  WrapperComponent: Pagination,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: ({ value, isActive }) => (
      <Pagination.Item data-value={value} active={isActive}>{value}</Pagination.Item>
    ),
    [ITEM_TYPES.ELLIPSIS]: ({ value, isActive, onClick }) => (
      <Pagination.Ellipsis data-value={value} onClick={onClick}/>
    ),
    [ITEM_TYPES.FIRST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.First data-value={1} disabled={isActive} onClick={onClick}/>
    ),
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ({ value, isActive, onClick }) => (
      <Pagination.Prev data-value={value} disabled={isActive} onClick={onClick}/>
    ),
    [ITEM_TYPES.NEXT_PAGE_LINK]: ({ value, isActive, onClick }) => (
      <Pagination.Next data-value={value} disabled={isActive} onClick={onClick}/>
    ),
    [ITEM_TYPES.LAST_PAGE_LINK]: ({ value, isActive, onClick }) => (
      <Pagination.Last data-value={value} disabled={isActive} onClick={onClick}/>
    ),
  },
});

export function PaginatedTable(props) {
  const {
    pageNumber,
    children,
    columns,
    limit,
    rows,
    totalRecords,
    onPaginationButtonClick,
    t,
  } = props;
  const totalPages = Math.ceil(totalRecords / limit);

  if (rows === undefined || totalRecords === undefined) {
    return <Spinner />;
  }

  const generateColumnHeader = (column, idx) => {
    return (
      <th
        key={idx}
        className={column.className}
      >
        {column.title}
      </th>
    );
  };

  return (
    <React.Fragment>
      <div className="regenagri-table-pagination-controls">
        <InternalPagination
          currentPage={Math.min(totalPages, pageNumber + 1)}
          totalPages={totalPages}
          onClick={onPaginationButtonClick}
          hidePreviousAndNextPageLinks
          hideFirstAndLastPageLinks
          siblingPagesRange={1}
        />
        <div className="regenagri-table-total-records">
          ({t('totalRecordsWithCount', { count: totalRecords })})
        </div>
      </div>
      <Table responsive striped>
        <thead>
          <tr>
            { columns.map((column, idx) => generateColumnHeader(column, idx)) }
          </tr>
        </thead>
        <tbody>
          { children(rows) }
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default function PaginatedTableWrapper(props) {
  const {
    children,
    columns,
    fetchData,
    fetchFinally,
    setError,
    limit,
  } = props;

  const { t } = useTranslation();

  const [rows, setRows] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(0);

  const skip = pageNumber * limit;

  const onPaginationButtonClick = (event) => {
    const a = event.target;
    const pageNumber = a.dataset.value ? parseInt(a.dataset.value, 10) : parseInt(a.parentNode.dataset.value, 10);
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    const fetchAllRows = async () => {
      try {
        const { data, totalRecords: queryTotalRecords } = await fetchData(limit, skip);
        setRows(data);
        setTotalRecords(queryTotalRecords);
      } catch (err) {
        setError(`${t('An error occurred whilst trying to get user information:')} ${t(err.message)}`);
      } finally {
        fetchFinally();
      }
    };
    fetchAllRows();
  }, [fetchData, setError, fetchFinally, skip, limit, t]);

  return <PaginatedTable
    pageNumber={pageNumber}
    columns={columns}
    limit={limit}
    rows={rows}
    totalRecords={totalRecords}
    onPaginationButtonClick={onPaginationButtonClick}
    t={t}
  >
    { children }
  </PaginatedTable>;
}
