import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { RedirectContext } from 'contexts/Routing';
import BackendClientContext from 'contexts/BackendClient';
import Spinner from 'components/Spinner';

export function VerifyGroupInvitation(props) {
  const { verificationSuccess, error } = props;

  const Redirect = useContext(RedirectContext);

  if (error) {
    return (
      <Alert variant="danger">{error}</Alert>
    );
  }

  if (verificationSuccess) {
    return <Redirect to='/login?groupInvitationVerified=true' />;
  }
  return <Spinner />;
}

export default function VerifyGroupInvitationWrapper(props) {
  const { match } = props;
  const { groupId, userId } = match.params;

  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [error, setError] = useState(null);

  const backendClient = useContext(BackendClientContext);

  useEffect(() => {
    const verifyInvitation = async () => {
      try {
        await backendClient.verifyInvitation(groupId, userId);
        setVerificationSuccess(true);
      } catch (err) {
        setError(`An error occurred while trying to accept the group invitation: ${err.message}`);
      }
    };

    verifyInvitation();
  }, [groupId, userId, backendClient]);

  return <VerifyGroupInvitation
    error={error}
    verificationSuccess={verificationSuccess}
  />;
}
