import React, { useContext, useEffect, useState } from "react";
import Select from "react-multiselect-checkboxes";

import BackendClientContext from 'contexts/BackendClient';
import Translation from 'locales/Translation';

import { customStyles } from './dropdownCustomStyles';

const { useTranslation } = Translation.setup();

export function mapGroupResponseToOptions(groups) {
  return groups
    .map(({ id, name }) => ({ value: id, label: name }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function SelectGroup(props) {
  const {
    groups,
    selectedGroupIds,
    setGroupIds,
    defaultGroupIds,
    t,
  } = props;

  if (!groups || groups.length === 0) {
    return null;
  }

  const handleChange = (selection) => {
    const newGroupIds = selection.map(opt => opt.value);
    setGroupIds(newGroupIds);
  };

  const options = mapGroupResponseToOptions(groups);

  const getOptions = (groupIds) => {
    if (groupIds && groupIds.length > 0) {
      return options.filter(opt => groupIds.includes(opt.value));
    }
    return [];
  };

  return (
    <div className={"regenagri-group-select-container"}>
      <Select
        styles={customStyles}
        width={"100%"}
        placeholderButtonLabel={t("Select Group...")}
        options={options}
        onChange={handleChange}
        value={getOptions(selectedGroupIds)}
        defaultValue={getOptions(defaultGroupIds)}
      />
    </div>
  );
}

export default function SelectGroupWrapper(props) {
  const {
    setError,
    userId,
    setGroupIds,
    defaultGroupIds,
  } = props;

  const [groups, setGroups] = useState(null);
  const [selectedGroupIds, setSelectedGroupIds] = useState(defaultGroupIds);

  const backendClient = useContext(BackendClientContext);

  const { t } = useTranslation();

  useEffect(() => {
    const getUserGroups = async (currentUserId) => {
      try {
        const { groups: userGroups } = await backendClient.listUserGroups(currentUserId);
        setGroups(userGroups);
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve the users groups")}: ${t(err.message)}`);
      }
    };

    if (userId !== undefined) {
      getUserGroups(userId);
    }
  }, [t, backendClient, setError, userId]);

  return <SelectGroup
    groups={groups}
    setGroupIds={(groupIds) => {
      setSelectedGroupIds(groupIds);
      setGroupIds(groupIds);
    }}
    defaultGroupIds={defaultGroupIds}
    selectedGroupIds={selectedGroupIds}
    t={t}
  />;
}
