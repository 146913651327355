import React from 'react';
import moment from 'moment';

import { getQueryParam } from 'common/queryString';
import { parseBoolean } from 'common/utils';

import countryMappings from "../../resources/countries.json";

export function CertifiedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#e5f9f5" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
      <path
        d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"
      />
    </svg>
  );
}

export function toFilterValue(value) {
  return value.replace(" ", "").toLowerCase();
}

function _getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}

export function assessmentsToSelectData(assessments, keyField) {
  if (!assessments.length) return [];
  const result = assessments.map(assessment => (assessment[keyField] ? {
    value: toFilterValue(assessment[keyField]),
    label: assessment[keyField],
  } : undefined));
  const filtered = result.filter((item) => item !== undefined);
  return _getUniqueListBy(filtered, "label")
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getYears(assessments) {
  if (!assessments.length) return [];
  const toYear = (dateTime) => moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS').year();
  const result = assessments.map(assessment => (assessment.created ? {
    value: toYear(assessment.created),
    label: `${toYear(assessment.created)}`,
  } : undefined));

  const filtered = result.filter((item) => item !== undefined);
  return _getUniqueListBy(filtered, "label")
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getCountryLabels(assessments, t) {
  if (!assessments.length) return [];
  const toCountryLabel = (countryId) => t(countryMappings[countryId]);
  const result = assessments.map(assessment => (assessment.country ? {
    value: assessment.country,
    label: toCountryLabel(assessment.country),
  } : undefined));

  const filtered = result.filter((item) => item !== undefined);
  return _getUniqueListBy(filtered, "label")
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function stripUndefinedValues(filters) {
  const mutableFilters = { ...filters };
  Object.entries(mutableFilters).forEach(([k, v]) => {
    if (v === undefined) {
      delete mutableFilters[k];
    }
  });

  return mutableFilters;
}

function getArrayifiedParams(key) {
  const arrayify = (thing) => {
    const result = Array.isArray(thing) ? thing : [thing];
    return result.filter(entry => entry !== undefined);
  };
  return arrayify(getQueryParam(key));
}

export function buildFiltersFromUrl() {
  const keys = [
    'farmName',
    'createdYear',
    'country',
    'typeOfFarm',
    'completed',
  ];

  const filters = Object.fromEntries(keys.map(key => ([key, getArrayifiedParams(key)])));
  filters.completed = filters.completed.map(parseBoolean);
  filters.createdYear = filters.createdYear.map(parseInt).filter(Number.isInteger);
  return stripUndefinedValues(filters);
}
