import React, { useContext } from 'react';
import {
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import config from 'common/config';

import AuthContext from 'contexts/Auth';
import BackendClientContext from 'contexts/BackendClient';

function OpenAssessmentMenuItem({
  assessment,
  sectionId,
  title,
  setAssessmentPath,
  t,
}) {
  return (
    <Dropdown.Item onClick={() => setAssessmentPath({
      assessmentId: assessment.id,
      sectionId,
    })}>
      { t(title) }
    </Dropdown.Item>
  );
}

export function AssessmentAdminActionsMenu({
  assessment,
  patchAssessment,
  setAssessmentPath,
  setError,
  t,
}) {
  const backendClient = useContext(BackendClientContext);
  const auth = useContext(AuthContext);
  const currentUserId = auth.getUserId();

  const reopenAssessment = async () => {
    try {
      await backendClient.reopenAssessment(assessment.id);
      patchAssessment(assessment.id, { completed: false });
    } catch (err) {
      setError(`${t("Unable to reopen assessment:")} ${t(err.message)}`);
    }
  };

  const menuItems = [];
  if (assessment.createdBy === currentUserId && !assessment.completed) {
    menuItems.push(<OpenAssessmentMenuItem
      assessment={assessment}
      sectionId={config.firstAssessmentFormId}
      title={"Edit"}
      key={"edit-assessment"}
      setAssessmentPath={setAssessmentPath}
      t={t}
    />);
  }
  if (assessment.lastModifiedSection && assessment.createdBy !== currentUserId) {
    menuItems.push(<OpenAssessmentMenuItem
      assessment={assessment}
      sectionId={config.firstAssessmentFormId}
      title={"View"}
      key={"view-assessment"}
      setAssessmentPath={setAssessmentPath}
      t={t}
    />);
  }
  if (assessment.completed) {
    menuItems.push(<OpenAssessmentMenuItem
      assessment={assessment}
      sectionId={"scorecard"}
      title={"View Scorecard"}
      key={"view-scorecard"}
      setAssessmentPath={setAssessmentPath}
      t={t}
    />);
    menuItems.push(
      <Dropdown.Item
        onClick={() => reopenAssessment()}
        key={"reopen-assessment"}
      >
        {t("Reopen Assessment")}
      </Dropdown.Item>
    );
  }
  if (menuItems.length === 0) {
    return null;
  }
  return (
    <DropdownButton title={t("Actions")}>
      {menuItems}
    </DropdownButton>
  );
}
