export function buildCombinedScorecardUrl(
  baseUrl,
  selectedAssessments,
  farmNames,
  totalAreaOfLandBeingAssessed,
  totalAreaOfLandBeingAssessedUnit,
  totalAgriculturalLand,
  totalAgriculturalLandUnit,
  totalConservationArea,
  totalConservationAreaUnit,
) {
  const assessmentParams = `assessmentId=${Array.from(selectedAssessments).join('&assessmentId=')}`;
  const farmsNamesParams = farmNames?.length > 0 ?
    `&farmName=${farmNames.map(name => encodeURIComponent(name)).join("&farmName=")}`
    : '';
  const totalAreaAssessedParams = totalAreaOfLandBeingAssessed ?
    `&totalAreaOfLandBeingAssessed=${totalAreaOfLandBeingAssessed}&totalAreaOfLandBeingAssessedUnit=${totalAreaOfLandBeingAssessedUnit}`
    : '';
  const totalAgriculturalLandQueryParams = totalAgriculturalLand ?
    `&totalAgriculturalLand=${totalAgriculturalLand}&totalAgriculturalLandUnit=${totalAgriculturalLandUnit}`
    : '';
  const totalConservationAreaParams = totalConservationArea ?
    `&totalConservationArea=${totalConservationArea}&totalConservationAreaUnit=${totalConservationAreaUnit}`
    : '';
  return baseUrl
    + assessmentParams
    + farmsNamesParams
    + totalAreaAssessedParams
    + totalAgriculturalLandQueryParams
    + totalConservationAreaParams;
}

export function ensureFalsyOrArray(param) {
  if (param === '') {
    return [''];
  }
  return !param || Array.isArray(param) ? param : [param];
}
