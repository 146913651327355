import React from 'react';
import Translation from 'locales/Translation';
import { GREEN } from 'common/scorecard';

const { useTranslation } = Translation.setup();

function SynergyPoint({ synergyPoint, t }) {
  return (
    <div>
      <div className="regenagri-scorecard-section-header">
        <h4> {t(synergyPoint.name)} </h4>
      </div>
      <p className="regenagri-synergy-point-description">{t(synergyPoint.description)}</p>
    </div >
  );
}

export default function SynergyPoints({ synergyPoints }) {
  const { t } = useTranslation();

  if (synergyPoints?.length > 0) {
    return (
      <>
        <div className="regenagri-synergy-points-header">
          <h2>{t('Synergy Points')}</h2>
          <p className="regenagri-synergy-point-description">{t('These are points recognising the extra value of a combination of practices')}</p>
        </div>
        <div className="regenagri-scorecard-section" style={{ borderLeft: `32px solid ${GREEN}` }}>
          {synergyPoints.map(synergyPoint => <SynergyPoint synergyPoint={synergyPoint} key={synergyPoint.id} t={t} />)}
        </div>
      </>
    );
  }
  return null;
}
