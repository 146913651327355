import React from 'react';

import cssVariables from 'variables.scss';

export function Barchart({
  Plot,
  id,
  data,
  title,
  tickvals,
  ticktext,
  yMax,
  containerSize,
  t,
  tickFormat,
}) {
  let width = Math.min(window.innerWidth, 800) * 0.85;

  if (containerSize.width > 0) {
    width = containerSize.width * 0.95;
  }

  const height = width / 1.6;
  return (
    <div className="regenagri-scorecard-chart" key={id}>
      <Plot
        data={data}
        layout={{
          width,
          height,
          title: t(title),
          hovermode: 'closest',
          xaxis: {
            tickmode: 'array',
            tickvals,
            ticktext,
          },
          yaxis: {
            range: [0, yMax],
            tickformat: tickFormat || ',d',
          },
          font: {
            family: `Bitter, serif`,
            color: cssVariables.textColour,
          },
        }}
      />
    </div>
  );
}
