/**
 * It is assumed that the array of items will not contain any duplicates.
 */
export function getPreviousItem(items, value, defaultValue) {
  const i = items.indexOf(value);

  if (i < 1) {
    return defaultValue;
  }
  return items[i - 1];
}

/**
 * It is assumed that the array of items will not contain any duplicates.
 */
export function getNextItem(items, value, defaultValue) {
  const i = items.indexOf(value);

  if (i === -1 || i === items.length - 1) {
    return defaultValue;
  }
  return items[i + 1];
}

export function dataFilterString(valueToCheck, valuesToMatch) {
  if (!valuesToMatch || valuesToMatch.length === 0) {
    return true;
  }
  const stringToCheck = valueToCheck ? valueToCheck.toLowerCase() : "";
  return valuesToMatch.some(value => stringToCheck.includes(value.toLowerCase()));
}

export function dataFilterValue(valueToCheck, valuesToMatch) {
  if (!valuesToMatch || valuesToMatch.length === 0) {
    return true;
  }

  return valuesToMatch.some(value => value === valueToCheck);
}

export function parseBoolean(boolStr) {
  return ["true", "yes"].includes(boolStr.toLowerCase()) || boolStr === "1";
}
