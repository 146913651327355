import React, { useState, useContext, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import AuthContext from "contexts/Auth";
import AssessmentsTable from 'components/AssessmentsTable';
import backendClientContext from 'contexts/BackendClient';
import { RedirectContext } from 'contexts/Routing';

import Translation from 'locales/Translation';
import { ASSESSMENTS_PRIMER } from 'templates/assessments';

import './styles.scss';

const { useTranslation } = Translation.setup();

export function Assessments(props) {
  const {
    assessmentPath,
    createAssessment,
    error,
    existingAssessments,
    isAdmin,
    isAuthenticated,
    isGroupAdmin,
    patchAssessment,
    redirectUrl,
    setAssessmentPath,
    t,
  } = props;

  const Redirect = useContext(RedirectContext);

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (assessmentPath) {
    return <Redirect push to={`/assessments/${assessmentPath.assessmentId}/${assessmentPath.sectionId}`} />;
  }

  if (isAdmin) {
    return <Redirect to="/admin/dashboard" />;
  }

  if (isGroupAdmin) {
    return <Redirect to="/group-admin/dashboard" />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login?authRequired=true" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')} - {t('assessments')}</title>
      </Helmet>
      <h1>{t("Assessments")}</h1>
      <ASSESSMENTS_PRIMER />
      <div className="divider" />
      <p>
        {t("Press the button below to start a new assessment.")}
      </p>
      { error && <Alert variant="danger">{error}</Alert>}
      <Button data-testid="start-assessment" onClick={() => createAssessment()}>{t("Start a new assessment")}</Button>
      <div className="divider" />
      <h2>{t("existing assessments")}</h2>
      <AssessmentsTable
        assessments={existingAssessments}
        setAssessmentPath={setAssessmentPath}
        patchAssessment={patchAssessment}
      />
    </React.Fragment>
  );
}

export function AssessmentsWrapper() {
  const { t } = useTranslation();

  const [assessmentPath, setAssessmentPath] = useState(null);
  const [error, setError] = useState(null);
  const [existingAssessments, setExistingAssessments] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const auth = useContext(AuthContext);
  const backendClient = useContext(backendClientContext);

  const createAssessment = async () => {
    setRedirectUrl(`/create-assessment`);
  };

  useEffect(() => {
    const getExistingAssessments = async () => {
      try {
        const { assessments } = await backendClient.getAssessments();
        setExistingAssessments(assessments);
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve existing assessments:")} ${t(err.message)}`);
      }
    };

    getExistingAssessments();
  }, [setExistingAssessments, t, backendClient]);

  const patchAssessment = (assessmentId, patch) => {
    const newAssessments = existingAssessments.map(assessment => {
      if (assessment.id === assessmentId) {
        return {
          ...assessment,
          ...patch,
        };
      }
      return assessment;
    });
    setExistingAssessments(newAssessments);
  };

  return <Assessments
    assessmentPath={assessmentPath}
    createAssessment={createAssessment}
    error={error}
    existingAssessments={existingAssessments}
    isAdmin={auth.isAdmin()}
    isAuthenticated={auth.isAuthenticated()}
    isGroupAdmin={auth.isGroupAdmin()}
    redirectUrl={redirectUrl}
    setAssessmentPath={setAssessmentPath}
    patchAssessment={patchAssessment}
    t={t}
  />;
}

export default AssessmentsWrapper;
