import React, { useState } from 'react';
import {
  Alert,
  Button,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import backendClient from 'clients/backendClient';
import Spinner from 'components/Spinner';
import Translation from 'locales/Translation';

import './styles.scss';

const { useTranslation } = Translation.setup();

const RETURN_KEY = 13;

export function AddUser(props) {
  const {
    createUser,
    emailAddress,
    handleInputChange,
    setEmailAddress,
    submitButtonDisabled,
    success,
    userBeingCreated,
    t,
  } = props;

  if (userBeingCreated) {
    return <Spinner />;
  }

  const onSubmitClicked = async () => {
    if (emailAddress && emailAddress.value) {
      await createUser(emailAddress.value);
      emailAddress.value = '';
      handleInputChange();
    }
  };

  const onKeyUp = async (event) => {
    if (event.charCode === RETURN_KEY) {
      onSubmitClicked(event);
    }
  };

  return (
    <React.Fragment>
      { success && <Alert variant="success">{ success }</Alert> }
      <InputGroup className="regenagri-add-user-form">
        <FormControl
          placeholder={t("Email Address")}
          aria-label="emailaddress"
          data-testid="email-address"
          ref={(ref) => setEmailAddress(ref)}
          onChange={handleInputChange}
          onKeyPress={onKeyUp}
        />
        <Button
          disabled={submitButtonDisabled}
          onClick={onSubmitClicked}
        >
          {t("Create User")}
        </Button>
      </InputGroup>
    </React.Fragment>
  );
}

export default function AddUserWrapper(props) {
  const { t } = useTranslation();

  const { setError } = props;

  const [emailAddress, setEmailAddress] = useState(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [success, setSuccess] = useState(null);
  const [userBeingCreated, setUserBeingCreated] = useState(false);

  const handleInputChange = () => {
    if (emailAddress && emailAddress.value) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const createUser = async (newEmailAddress) => {
    setUserBeingCreated(true);
    try {
      const { userId } = await backendClient.createUser(newEmailAddress);
      await backendClient.upgradeUser(userId);
      setError('');
      setSuccess(`${newEmailAddress} ${t("created successfully")}`);
    } catch (err) {
      setSuccess('');
      setError(`${t("Unable to create user:")} ${t(err.message)}`);
    }
    setUserBeingCreated(false);
  };

  return <AddUser
    createUser={createUser}
    handleInputChange={handleInputChange}
    emailAddress={emailAddress}
    setEmailAddress={setEmailAddress}
    submitButtonDisabled={submitButtonDisabled}
    success={success}
    userBeingCreated={userBeingCreated}
    t={t}
  />;
}
