import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import config from 'common/config';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function OpenAssessmentButton({
  assessment,
  sectionId,
  title,
  setAssessmentPath,
}) {
  const { t } = useTranslation();
  return (
    <Button onClick={() => setAssessmentPath({
      assessmentId: assessment.id,
      sectionId,
    })}>
      {t(title)}
    </Button>
  );
}

function ChangeGroupButton({ assessment, title, setAssessmentToChangeGroupFor }) {
  const { t } = useTranslation();
  const { id, groups } = assessment;
  return (
    <Button
      onClick={() => { setAssessmentToChangeGroupFor({ id, groups }); }}
      key={"change-group"}
    >
      {t(title)}
    </Button>
  );
}

export function AssessmentActionButtons({
  assessment,
  currentUserId,
  setAssessmentPath,
  userIsInAGroup,
  patchAssessment,
  setAssessmentToChangeGroupFor,
}) {
  const isReadOnly = assessment.createdBy !== currentUserId;
  const buttons = [];
  if (!isReadOnly && !assessment.completed) {
    buttons.push(
      <OpenAssessmentButton
        assessment={assessment}
        sectionId={config.firstAssessmentFormId}
        title={"Edit"}
        key={"edit-assessment"}
        setAssessmentPath={setAssessmentPath}
      />
    );
  }
  if (isReadOnly && assessment.lastModifiedSection) {
    buttons.push(
      <OpenAssessmentButton
        assessment={assessment}
        sectionId={config.firstAssessmentFormId}
        title={"View"}
        key={"view-assessment"}
        setAssessmentPath={setAssessmentPath}
      />
    );
  }
  if (assessment.completed) {
    buttons.push(
      <OpenAssessmentButton
        assessment={assessment}
        sectionId={"scorecard"}
        title={"View Scorecard"}
        key={"view-scorecard"}
        setAssessmentPath={setAssessmentPath}
      />
    );
  }
  if (!isReadOnly && userIsInAGroup && patchAssessment) {
    buttons.push(
      <ChangeGroupButton
        assessment={assessment}
        title={"Change Group"}
        key={'change-group'}
        setAssessmentToChangeGroupFor={setAssessmentToChangeGroupFor}
      />
    );
  }

  return (
    buttons.length ?
      <ButtonGroup>
        {buttons}
      </ButtonGroup>
      : null
  );
}
