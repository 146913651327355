import React from 'react';
import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import RegenAgriForm from 'components/RegenAgriForm';
import Translation from 'locales/Translation';

const { useTranslation } = Translation.setup();

function ResetPasswordForm(props) {
  const { t } = useTranslation();
  const { match } = props;
  const existingSubmission = {
    data: {
      resetToken: match.params.token,
    },
  };

  return (
    <div className="regenagri-small-form-box">
      <Alert variant="info">
        {t(`Please enter a new password.`)}
      </Alert>
      <RegenAgriForm
        formId={"passwordreset"}
        successMarkup={<Redirect to='/login?passwordReset=true' />}
        requiresAuthentication={false}
        existingSubmission={existingSubmission}
      />
    </div>
  );
}

export default ResetPasswordForm;
