import React from 'react';
import './styles.scss';

import cssVariables from 'variables.scss';

const color = cssVariables.thirdPrimaryColor;
export const element = (
  <div className="regenagri-spinner">
    <svg id="L4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 100">
      <circle fill={color} cx="6" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill={color} cx="26" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill={color} cx="46" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  </div>
);

export default function Spinner(Component, isLoading) {
  return isLoading ? element : Component;
}
