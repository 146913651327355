export function calculateFarmSummary(scorecardResult) {
  const { assessmentDetails } = scorecardResult;

  return {
    totalAreaOfLandBeingAssessed: assessmentDetails?.totalAreaOfLandBeingAssessed,
    totalAgriculturalLand: assessmentDetails?.totalAgriculturalLand,
    totalConservationArea: assessmentDetails?.totalConservationArea,
    amountOfWaterUsedOnFarm: assessmentDetails?.amountOfWaterUsedOnFarm,
    totalAmountOfSyntheticFertilisers: assessmentDetails?.totalAmountOfSyntheticFertilisers,
    totalEnergyUse: assessmentDetails?.totalEnergyUse,
    totalGreenhouseGases: assessmentDetails?.totalEmissions,
  };
}
