import React, { useEffect, useContext, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import config from 'common/config';
import Spinner from 'components/Spinner';
import { getPreviousItem } from 'common/utils';
import AuthContext from 'contexts/Auth';
import BackendClientContext from 'contexts/BackendClient';
import RegenAgriFormContext from 'contexts/RegenAgriForm';
import { RedirectContext } from 'contexts/Routing';
import Translation from 'locales/Translation';

import "./styles.scss";
import { RedirectModal } from './RedirectModal';
import {
  ASSESSMENT_FORM_TITLES,
  NextSectionButton,
  PAGE_NAVIGATION_WARNING_MESSAGE,
  getNextForm,
  isEditable,
  renderTopButtons,
} from './utils';

const { useTranslation } = Translation.setup();

export function AssessmentForm(props) {
  const {
    assessmentFormIds,
    assessmentId,
    error,
    existingSubmission,
    isReadonly,
    redirectModalLocation,
    redirectUrl,
    sectionId,
    setRedirectModalLocation,
    setRedirectUrl,
    showNextSectionButton,
    t,
  } = props;

  const Redirect = useContext(RedirectContext);
  const RegenAgriForm = useContext(RegenAgriFormContext);

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (!existingSubmission || !assessmentFormIds) {
    return <Spinner />;
  }

  // e.g. if the current user is trying to access a paid-member section
  // of the assessment
  if (!assessmentFormIds.includes(sectionId)) {
    return <Redirect to="/assessments" />;
  }

  function getPreviousForm() {
    if (!assessmentFormIds.includes(sectionId)) {
      return null;
    }

    return getPreviousItem(assessmentFormIds, sectionId, null);
  }

  const previousForm = getPreviousForm();
  const title = t(ASSESSMENT_FORM_TITLES[sectionId]);

  const redirectWithModalIfRequired = (url) => {
    if (isReadonly) {
      setRedirectUrl(url);
    } else {
      setRedirectModalLocation(url);
    }
  };

  const nextForm = getNextForm(sectionId, assessmentFormIds) || "scorecard";

  const onSuccess = () => {
    setRedirectUrl(`/assessments/${assessmentId}/${nextForm}`);
  };

  const topButtons = () => renderTopButtons(previousForm, redirectWithModalIfRequired, assessmentId, t);

  return (
    <React.Fragment>
      {
        title &&
          <Helmet>
            <title>{t('regenagri')} - { title.toLowerCase() }</title>
          </Helmet>
      }
      <Prompt
          when={true}
          message={PAGE_NAVIGATION_WARNING_MESSAGE}
        />
      <RedirectModal
        redirectModalLocation={redirectModalLocation}
        setRedirectUrl={setRedirectUrl}
        setRedirectModalLocation={setRedirectModalLocation}
        message={PAGE_NAVIGATION_WARNING_MESSAGE}
      />
      <h1>{ title }</h1>
      { error && <Alert variant="danger">{ t(error) }</Alert> }
      <RegenAgriForm
        renderTopButtons={topButtons}
        formId={`assessments/${sectionId}`}
        requiresAuthentication={true}
        onSuccess={onSuccess}
        existingSubmission={existingSubmission}
        submissionId={existingSubmission._id}
        isReadonly={isReadonly}
      />
      { isReadonly && showNextSectionButton && <NextSectionButton onSuccess={onSuccess} /> }
    </React.Fragment>
  );
}

function AssessmentFormWrapper(props) {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const backendClient = useContext(BackendClientContext);

  const { match } = props;
  const id = match.params.assessmentId;
  const { sectionId } = match.params;

  const [existingSubmission, setExistingSubmission] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [redirectModalLocation, setRedirectModalLocation] = useState(null);
  const [error, setError] = useState(null);
  const [assessmentFormIds, setAssessmentFormIds] = useState(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [showNextSectionButton, setShowNextSectionButton] = useState(false);

  const currentUserId = auth.getUserId();

  useEffect(() => {
    const getAssessmentForms = async () => {
      try {
        const assessmentForms = await backendClient.getAssessmentFormIdsAsync(id);
        setAssessmentFormIds(assessmentForms);
      } catch (err) {
        setError(t('Unexpected error: Could not retrieve assessment form list'));
      }
    };

    getAssessmentForms();
  }, [backendClient, id, t]);

  const isDairyFarm = async (assessmentId) => {
    try {
      const result = await backendClient.getAssessmentSubmission(config.profileInformationFormId, assessmentId);
      return result.data.typeOfFarm.dairy;
    } catch (err) {
      return false;
    }
  };

  const augmentSubmissionWithRequiredFlags = async (currentSectionId, submission) => {
    const augmentedSubmission = { ...submission };
    const formsThatRequireDairyFlag = [config.fieldsFormId, config.livestockFormId, config.energyFormId];
    if (formsThatRequireDairyFlag.includes(currentSectionId)) {
      augmentedSubmission.data.isDairyFarm = await isDairyFarm(id);
    }
    return augmentedSubmission;
  };

  // this effect should only run after assessmentFormIds have been retrieved
  useEffect(() => {
    const setReadOnlyState = async () => {
      const { assessment } = await backendClient.getAssessment(id);
      setIsReadonly(!isEditable(assessment, currentUserId));
    };

    setReadOnlyState();

    const getAssessmentSubmission = async () => {
      try {
        const submission = await backendClient.getAssessmentSubmission(sectionId, id);
        const augmentedSubmission = await augmentSubmissionWithRequiredFlags(sectionId, submission);
        setExistingSubmission(augmentedSubmission);
      } catch (err) {
        const data = { assessmentId: id };
        if (sectionId === config.profileInformationFormId) {
          const { farm } = await backendClient.getFarmForAssessment(id);
          if (farm) {
            data.isFarmLinkedAssessment = true;
            data.farmNameLabel = farm.name;
          }
        }
        const augmentedSubmission = await augmentSubmissionWithRequiredFlags(sectionId, { data });
        setExistingSubmission(augmentedSubmission);
      }
    };

    if (!existingSubmission && assessmentFormIds) {
      getAssessmentSubmission();
    }
  }, [assessmentFormIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Prompt the user before refreshing
    window.onbeforeunload = () => PAGE_NAVIGATION_WARNING_MESSAGE;
    return () => {
      // Remove prompt when navigating away from the page
      window.onbeforeunload = undefined;
    };
  }, []);

  useEffect(() => {
    const setEnablednessForNextSection = async () => {
      const { assessment } = await backendClient.getAssessment(id);
      setShowNextSectionButton(assessment.data.completed || assessment.data.lastModifiedSection !== sectionId);
    };
    if (isReadonly) {
      setEnablednessForNextSection();
    }
  }, [isReadonly, sectionId, id, backendClient]);

  return (
    <AssessmentForm
      assessmentFormIds={assessmentFormIds}
      assessmentId={id}
      error={error}
      existingSubmission={existingSubmission}
      redirectModalLocation={redirectModalLocation}
      redirectUrl={redirectUrl}
      sectionId={sectionId}
      setRedirectModalLocation={setRedirectModalLocation}
      setRedirectUrl={setRedirectUrl}
      isReadonly={isReadonly}
      showNextSectionButton={showNextSectionButton}
      t={t}
    />
  );
}

export default AssessmentFormWrapper;
