import React from 'react';
import { Row } from 'react-bootstrap';

import FilteredSelectionWrapper from 'components/DropDownFilter';
import Translation from 'locales/Translation';
import { toFilterValue } from './utils';

import './styles.scss';

const { useTranslation } = Translation.setup();

const FARM_TYPES = {
  "livestock": "Livestock",
  "dairy": "Dairy",
  "arable": "Arable",
  "topFruits": "Top Fruits",
  "freshProduce": "Fresh Produce",
};

export function FilterPanel(props) {
  const {
    farmNameSelectData,
    yearSelectData,
    countrySelectData,
    selectedFilterData,
    setSelectedFilterData,
    t,
  } = props;

  function formatBoolean(b) {
    return b ? t("yes") : t("no");
  }

  const countryIdMappings = Object.fromEntries(countrySelectData.map(country => ([country.value, country.label])));

  const farmTypeOptions = Object.entries(FARM_TYPES).map(([farmType, label]) => ({
    value: farmType,
    label: t(label),
  }));

  const completedOptions = [
    {
      value: true,
      label: formatBoolean(true),
    },
    {
      value: false,
      label: formatBoolean(false),
    },
  ];

  return (
    <div id="regenagri-filter-panel">
      <Row> {
        FilteredSelectionWrapper({
          className: "col-md-6",
          selectData: farmNameSelectData,
          selectedFilterData,
          setSelectedFilterData,
          filterKey: "farmName",
          placeHolderText: t("Search by farm name ..."),
          optionToFilterKey: (filterOption) => filterOption.label,
          filterKeyToOption: (filter) => ({
            value: toFilterValue(filter),
            label: filter,
          }),
        })
      } {
        FilteredSelectionWrapper({
          className: "col-md-6",
          selectData: completedOptions,
          selectedFilterData,
          setSelectedFilterData,
          filterKey: "completed",
          placeHolderText: t("Search by completed state ..."),
          filterKeyToOption: (filter) => ({
            value: filter,
            label: formatBoolean(filter),
          }),
        })
      } </Row>
      <Row> {
        FilteredSelectionWrapper({
          className: "col-md-4",
          selectData: yearSelectData,
          selectedFilterData,
          setSelectedFilterData,
          filterKey: "createdYear",
          placeHolderText: t("Search by created year ..."),
          filterKeyToOption: (filter) => ({
            value: filter,
            label: filter.toString(),
          }),
        })
      } {
        FilteredSelectionWrapper({
          className: "col-md-4",
          selectData: countrySelectData,
          selectedFilterData,
          setSelectedFilterData,
          filterKey: "country",
          placeHolderText: t("Search by country ..."),
          filterKeyToOption: (countryId) => ({
            value: countryId,
            label: countryIdMappings[countryId],
          }),
        })
      } {
        FilteredSelectionWrapper({
          className: "col-md-4",
          selectData: farmTypeOptions,
          selectedFilterData,
          setSelectedFilterData,
          filterKey: "typeOfFarm",
          placeHolderText: t("Search by farm type ..."),
          filterKeyToOption: (farmType) => ({
            value: farmType,
            label: t(FARM_TYPES[farmType]),
          }),
        })
      }
      </Row>
    </div>
  );
}

export default function FilterPanelWrapper(props) {
  const {
    farmNameSelectData,
    yearSelectData,
    countrySelectData,
    selectedFilterData,
    setSelectedFilterData,
  } = props;

  const { t } = useTranslation();

  return <FilterPanel
    farmNameSelectData = {farmNameSelectData}
    yearSelectData = {yearSelectData}
    countrySelectData = {countrySelectData}
    selectedFilterData = {selectedFilterData}
    setSelectedFilterData = {setSelectedFilterData}
    t = {t}
  />;
}
