import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import qs from 'qs';

import config from 'common/config';
import AuthContext from 'contexts/Auth';
import Spinner from 'components/Spinner';
import { RedirectContext } from 'contexts/Routing';
import backendClientContext from 'contexts/BackendClient';
import AddFarm from 'components/AddFarm';
import SelectGroup from 'components/SelectGroup';

import Translation from 'locales/Translation';

import './styles.scss';

const { useTranslation } = Translation.setup();

function FarmRow({ farm, createAssessment }) {
  const { t } = useTranslation();
  return (
    <tr key={farm.id}>
      <td>{farm.name || t('Not yet specified')}</td>
      <td className="regenagri-farms-create-assessment">
        <Button onClick={() => createAssessment(farm.id)}>{t("Start a new assessment")}</Button>
      </td>
    </tr>
  );
}

function TopButtons({ setRedirectUrl }) {
  const { t } = useTranslation();
  return (
    <div className="regenagri-top-buttons">
      <Button
        data-testid="back-to-assessments"
        onClick={() => setRedirectUrl(`/assessments`)}
      >
        {t('Back To Assessments')}
      </Button>
    </div>
  );
}

function GroupSelector({
  setError,
  setGroupIds,
  userId,
}) {
  const auth = useContext(AuthContext);
  if (auth.isGroupAdmin()) {
    return <h1>{auth.getGroupName()}</h1>;
  }
  return <SelectGroup
    setError={setError}
    userId={userId || auth.getUserId()}
    setGroupIds={setGroupIds}
  />;
}

export function Farms(props) {
  const {
    farms,
    isAuthenticated,
    redirectUrl,
    setRedirectUrl,
    error,
    setError,
    onNewFarmAdded,
    createAssessment,
    assessmentPath,
    pendingNewAssessment,
    setGroupIds,
    userId,
  } = props;

  const Redirect = useContext(RedirectContext);
  const { t } = useTranslation();

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login?authRequired=true" />;
  }

  if (assessmentPath) {
    return <Redirect push to={`/assessments/${assessmentPath.assessmentId}/${assessmentPath.sectionId}`} />;
  }

  if (pendingNewAssessment) {
    return <Spinner />;
  }

  const getFarmsTable = () => {
    if (!farms) {
      return <Spinner />;
    }

    if (!farms.length) {
      return null;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{t('regenagri')} - {t('farms')}</title>
        </Helmet>
        <Table responsive striped className="regenagri-farms-table">
          <thead>
            <tr>
              <th className="col-sm-3 regenagri-farms-name">{t("Farm Name")}</th>
              <th className="col-sm-3 regenagri-farms-create-assessment"></th>
            </tr>
          </thead>
          <tbody>
            {farms
              .sort((a, b) => (a.name.localeCompare(b.name)))
              .map(farm => <FarmRow
                farm={farm}
                createAssessment={createAssessment}
                key={farm.id}
              />)}
          </tbody>
        </Table>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <TopButtons setRedirectUrl={setRedirectUrl} />
      {error && <Alert variant="danger">{error}</Alert>}
      <GroupSelector
        setError={setError}
        setGroupIds={setGroupIds}
        userId={userId}
      />
      <AddFarm
        setError={setError}
        onNewFarmAdded={onNewFarmAdded}
        userId={userId}
      />
      {getFarmsTable()}
    </React.Fragment>
  );
}

export default function FarmsWrapper(props) {
  const auth = useContext(AuthContext);

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [farms, setFarms] = useState(null);
  const [error, setError] = useState(null);
  const [assessmentPath, setAssessmentPath] = useState(null);
  const [pendingNewAssessment, setPendingNewAssessment] = useState(null);
  const [groupIds, setGroupIds] = useState(auth.isGroupAdmin() ? [auth.getGroupId()] : []);

  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { userId } = params;
  const backendClient = useContext(backendClientContext);
  const { t } = useTranslation();

  const createAssessment = async (farmId) => {
    setPendingNewAssessment(true);

    try {
      const { id } = await backendClient.createAssessment(farmId, userId, groupIds);
      window.localStorage.setItem("assessmentsRefUrl", '/assessments');
      setAssessmentPath({
        assessmentId: id,
        sectionId: config.firstAssessmentFormId,
      });
    } catch (err) {
      setError(`${t("An error occurred whilst trying to create an assessment:")} ${t(err.message)}`);
    }
  };

  useEffect(() => {
    const getFarms = async () => {
      try {
        const { farms: userFarms } = await backendClient.getUserFarms(userId);
        setFarms(userFarms);
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve existing assessments:")} ${t(err.message)}`);
      }
    };

    getFarms();
  }, [setFarms, t, backendClient, userId]);

  const onNewFarmAdded = (newFarm) => {
    setFarms([
      ...farms,
      newFarm,
    ]);
  };

  return (
    <Farms
      farms={farms}
      isAuthenticated={auth.isAuthenticated()}
      redirectUrl={redirectUrl}
      setRedirectUrl={setRedirectUrl}
      error={error}
      setError={setError}
      onNewFarmAdded={onNewFarmAdded}
      pendingNewAssessment={pendingNewAssessment}
      createAssessment={createAssessment}
      assessmentPath={assessmentPath}
      setGroupIds={setGroupIds}
      userId={userId}
    />
  );
}
